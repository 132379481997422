import React, { useState } from 'react';
import styles from './alertmessage.module.css'


const AlertMessage = ({ message, onClose,onNavigate }) => {
  const [isVisible, setIsVisible] = useState(true);

const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }

    // Call onNavigate when the close button is clicked
    if (onNavigate && message === 'Registration Successful!') {
      onNavigate();
    }
};

  return (
    <>
      {isVisible && (
        <div className={` card ${styles.card}`} >
          <span className=' text-danger'>{message}</span>
          <span className={` mt-2 ${styles.closebutton}`} onClick={handleClose} style={{width:"content-fit"}}>close</span>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
