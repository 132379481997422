import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { useNavigate } from "react-router-dom";
import { deletedSlot, getimeslot, updateSlot } from '../../services/auth-service/timeSlot';
import styles from './markfreeCalender.module.css';
import EditTimeSlot from './EditTimeSlot';
import moment from 'moment'; // Import moment library for date formatting

const EditMarkedSlot = ({ influencerId, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertData, setAlertData] = React.useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [slotsPerPage] = useState(10);

  // State variable to trigger re-fetching of time slots
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchTimeSlots = async () => {
      try {
        const response = await getimeslot(influencerId);
// Sort slots by date first, then by startTime for the same date
  const sortedSlots = response.data.sort((a, b) => {
  // Compare dates (a.date and b.date)
  const dateComparison = new Date(a.date) - new Date(b.date);
  // If dates are the same, compare start times (a.startTime and b.startTime)
  if (dateComparison === 0) {
    // Extract the start times from each slot
    const startTimeA = a.startTime;
    const startTimeB = b.startTime;
    // Compare start times using string comparison
    if (startTimeA < startTimeB) {
      return -1; // A should come before B
    } else if (startTimeA > startTimeB) {
      return 1; // A should come after B
    } else {
      return 0; // A and B are the same
    }
  }
  return dateComparison; // Return result of date comparison
});
        setTimeSlots(sortedSlots);
      } catch (error) {
        console.error('Error fetching time slots:', error);
      }
    };
    fetchTimeSlots();
  }, [influencerId, reloadData]);

  const indexOfLastSlot = currentPage * slotsPerPage;
  const indexOfFirstSlot = indexOfLastSlot - slotsPerPage;
  const currentSlots = timeSlots.slice(indexOfFirstSlot, indexOfLastSlot);
  const handleSlotSelect = (slot) => {
    if (slot) {
      setSelectedSlot(slot);
    } else {
      // If the clicked area is not a slot, deselect the selected slot
      setSelectedSlot(null);
    }
  };

  const handleSaveSlot = async (editedSlot) => {
    // Perform save operation with the edited slot data
    try {
      const res = await updateSlot(editedSlot);
      if (res) {
        // Toggle the state variable to trigger re-fetching of time slots
        setReloadData(!reloadData);
        setAlertData({
          message: 'Updated!!',
        });
      } else {
        setAlertData({
          message: 'Failed to Update Try Again!',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSlot = async (deleteslot) => {
    try {
      const res = await deletedSlot(deleteslot);
      console.log(res, "here");
      if (res) {
        // Toggle the state variable to trigger re-fetching of time slots
        setReloadData(!reloadData);
        setAlertData({
          message: 'Deleted!!',
        });
      } else {
        setAlertData({
          message: 'Failed to Delete Try Again!',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="container mt-5" style={{overflowY: "auto",maxHeight: "80%" }}>
        <div className="card">
          <span className={`btn btn-outline-dark close-btn ${styles.closebutton} mb-3`} onClick={onClose}>&times;</span>
          <div className="card-header">
            <h2 className="mb-0">Edit calendar time slots available for booking</h2>
          </div>
          <div className="card-body">
            {timeSlots.length > 0 ? (
              <>
                <p>Select a time slot to edit</p>
                <div className="row">
                  {currentSlots.filter((slot) => slot.participants === slot.total).map((slot) => (
                    <div
                      key={slot._id}
                      className={`col-md-4 mb-3 slot`}
                      onClick={() => handleSlotSelect(slot)}
                    >
                      {selectedSlot === slot ? (
                        <EditTimeSlot slot={selectedSlot} onSave={handleSaveSlot} onDelete={handleDeleteSlot} />
                      ) : (
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">
                              {slot.startTime} - {slot.endTime}
                            </h5>
                            <p className="card-text">
                              {moment(slot.date).format('DD/MM/YYYY')} {/* Format date as dd/mm/yyyy */}
                            </p>
                            <p><span style={{ fontSize: "100%" }}>Type: {slot.meetingType}</span></p>
                            <p><span style={{ fontSize: "70%" }}>Remaining {slot.participants}</span></p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {/* Pagination */}
                <nav className="mt-3">
                <ul className="pagination">
                  {Array.from({ length: Math.ceil(timeSlots.length / slotsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                      <button className="page-link ml-2" onClick={() => setCurrentPage(pageNumber)}>
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
              </>
            ) : (
              <p>Currently, no slots are available for booking.</p>
            )}
          </div>
        </div>
      </div>
      {alertData && (
        <div className="overlay">
          {/* You can add an overlay to dim the background if needed */}
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  );
};

export default EditMarkedSlot;
