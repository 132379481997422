import { decrypt } from "../common/decrpyt";
import HttpService from "../http-service/HttpService";


const fetchInfluencerRequests = async (_id, page) => {
    try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/influencerRequests?_id=${_id}&page=${page}`);
      // return response;
      if(response.status===200){
      const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    //return JSON.parse(decryptedResponse);
    const parsedResponse = JSON.parse(decryptedResponse);
      return { ...parsedResponse, status: response.status };
      }else{
        return response.status;
      }
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching Influencer requests:', error);
      if(error.response.status===204){
        return 204;
      }
      // Handle the error
    }
  };


//update status
const handleUpdateRequest = async (requestId,mystatus,paymentStatus) => {
        try {
          // Make a PUT request to update the request status
          const response = await HttpService.put(`${process.env.REACT_APP_API_URL}/api/updateRequest`, {
            _id: requestId,
            status: mystatus,
            paymentStatus
          });
    
          // Handle the response as needed
         // console.log(response.data);
         if(response.status===200){
          const encryptedData = response.data.data;
          const decryptedResponse = decrypt(encryptedData);
          const parsedResponse = JSON.parse(decryptedResponse);
          return { ...parsedResponse, status: response.status };
        }else{
          return response.status;
        }
        } catch (error) {
          // Handle errors
          console.error('Error updating request:', error);
        }
      };

// /updatesessionforadmin
const handleUpdateRequestForSessions = async (requestId,mystatus,paymentStatus,attendence,utr_no) => {
  try {
    // Make a PUT request to update the request status
    const response = await HttpService.put(`${process.env.REACT_APP_API_URL}/api/updatesessionMaster`, {
      _id: requestId,
      status: mystatus,
      paymentStatus,
      attendence,
      utr_no
    });
    if(response.status===200){
    // Handle the response as needed
   return response.data;}else{
    return response.status;
  }
  } catch (error) {
    // Handle errors
    console.error('Error updating request:', error);
  }
};

//delete Request
const handleDeleteRequest = async (_id) => {
    try {
      // Make a DELETE request to delete the request
      const response = await HttpService.delete(`${process.env.REACT_APP_API_URL}/api/deleteRequest`, {
        data: { _id: _id },
      });
      // Handle the response as needed
      if(response.status===200){
        // Handle the response as needed
       return response.data;}else{
        return response.status;
      }
    } catch (error) {
      // Handle errors
      console.error('Error deleting request:', error);
    }
  };

// to get all the request from influencer acceptedrequests
const fetchInfluencerAccept= async (_id, page) => {
    try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/acceptedrequests?_id=${_id}&page=${page}`);
      //return response;
      if(response.status===200){
        // Handle the response as needed
      const encryptedData = response.data.data;
      const decryptedResponse = decrypt(encryptedData);
      const parsedResponse = JSON.parse(decryptedResponse);
      return { ...parsedResponse, status: response.status };
      }else{
        return response.status;
      }
      // Handle the response data as needed
    } catch (error) {
      if(error.response.status===204){
        return 204;
      }
      // Handle the error
    }
  };

  // /api/updateAfterTimeRequestStatus
const fetchInfluencerAfterTimeComplete= async (_id, page) => {
    try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/updateAfterTimeRequestStatus?_id=${_id}&page=${page}`);
     // return response;
     
     if(response.status===200){
      const encryptedData = response.data.data;
      const decryptedResponse = decrypt(encryptedData);
      const parsedResponse = JSON.parse(decryptedResponse);
      return { ...parsedResponse, status: response.status };
     }else{
      return response.status;
     }
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching Influencer requests:', error);
      if(error.response.status===204){
        return 204;
      }
      // Handle the error
    }
  };

// /api/updateAfterTwodays
const UpdateRequestAfterTwo= async () => {
  try {
    const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/updateAfterTwodays`);
    if(response.status===200){
    return response;}else{
      return response.status;
     }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};


 // /acceptedrequestsclient 
 // to get all the request from Client acceptedrequests
const fetchClientAccept= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/acceptedrequestsclient?_id=${_id}&page=${page}`);
    //return response;
    if(response.status===200){
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    const parsedResponse = JSON.parse(decryptedResponse);
    return { ...parsedResponse, status: response.status };
    }else{
      return response.status;
     }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// fetch completed influencer /api/completedrequests
const fetchInfluencerComplete= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/completedrequests?_id=${_id}&page=${page}`);
    //return response;
    if(response.status===200){
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    const parsedResponse = JSON.parse(decryptedResponse);
    return { ...parsedResponse, status: response.status };
  }else{
    return response.status;
   }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// /api/declinerequests
// fetch decline influencer /api/completedrequests
const fetchInfluencerDecline= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/declinerequests?_id=${_id}&page=${page}`);
    //return response;
    if(response.status===200){
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    const parsedResponse = JSON.parse(decryptedResponse);
    return { ...parsedResponse, status: response.status };
  }else{
    return response.status;
   }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// /api/completedrequestsclient
const fetchClientCompleted= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/completedrequestsclient?_id=${_id}&page=${page}`);
    // return response;
    if(response.status===200){
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    const parsedResponse = JSON.parse(decryptedResponse);
    return { ...parsedResponse, status: response.status };
  }else{
    return response.status;
   }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// /api/declinerequestsclient
const fetchClientDeclined= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/declinerequestsclient?_id=${_id}&page=${page}`);
  //  return response;
  if(response.status===200){
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    const parsedResponse = JSON.parse(decryptedResponse);
    return { ...parsedResponse, status: response.status };
  }else{
    return response.status;
   }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

const confirmMail =async (maildata)=>{
  try{
  const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/confirmmail`,maildata)
  if(response.status===200){  
  return response;}
  else{
    return response.status;
   }
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
}

// fetch all request with total record for client
const fetchAllRequest= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/allclientrequest?_id=${_id}&page=${page}`);
    if(response.status===200){  
      return response;}
      else{
        return response.status;
       }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// fetch all request to influencer request /api/allinfluencerRequests
const fetchAllRequestInfluencer= async (_id, page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/allinfluencerRequests?_id=${_id}&page=${page}`);
    if(response.status===200){  
      return response;}
      else{
        return response.status;
       }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// /api/allRequests
const fetchAllRequestsTotal= async (page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/allRequests?page=${page}`);
    if(response.status===200){  
      return response;}
      else{
        return response.status;
       }
    // Handle the response data as needed
  } catch (error) {
    console.error('Error fetching Influencer requests:', error);
    if(error.response.status===204){
      return 204;
    }
    // Handle the error
  }
};

// /api/searchrequestdebounce
const AllSearchRequestDebounce = async (searchQuery,page) => {
  try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/searchrequestdebounce?search=${searchQuery}&page=${page}`);  //   setInfluencers(response.data.influencers);
      if(response.status===200){  
        return response;}
        else{
          return response.status;
         }
      } catch (error) {
        console.error('Error fetching influencers:', error);
        // Handle the error
      }
}

export {fetchInfluencerRequests,handleUpdateRequest,fetchInfluencerComplete,fetchInfluencerAfterTimeComplete,UpdateRequestAfterTwo,fetchInfluencerDecline,
  handleDeleteRequest,fetchInfluencerAccept,fetchClientAccept,confirmMail,fetchClientCompleted,fetchAllRequest,fetchAllRequestInfluencer,fetchClientDeclined,
  fetchAllRequestsTotal,handleUpdateRequestForSessions,AllSearchRequestDebounce}