import React, { useState } from 'react';
import moment from 'moment';

const EditTimeSlot = ({ slot, onSave, onDelete }) => {
  const [editedStartTime, setEditedStartTime] = useState(slot.startTime);
  const [editedEndTime, setEditedEndTime] = useState(slot.endTime);

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setEditedStartTime(newStartTime);

    // Calculate endTime as 30 minutes after startTime using Moment.js
    const startTime = moment(`2000-01-01T${newStartTime}`, 'YYYY-MM-DDTHH:mm');
    const endTime = startTime.clone().add(30, 'minutes');

    // Format endTime to HH:mm string
    const formattedEndTime = endTime.format('HH:mm');
    setEditedEndTime(formattedEndTime);
  };

  const handleSave = () => {
    onSave({
      ...slot,
      startTime: editedStartTime,
      endTime: editedEndTime
    });
  };

  const handleDelete = () => {
    onDelete(slot);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          Start Time:
          <input
            type="time"
            value={editedStartTime}
            onChange={handleStartTimeChange}
          />
          End Time:
          <input
            type="text"
            value={editedEndTime}
            readOnly
          />
        </h5>
        <button className="btn btn-primary mt-2 mr-2" onClick={handleSave}>
          Save
        </button>
        <button className="btn btn-danger mt-2" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default EditTimeSlot;
