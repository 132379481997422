import React, { useState } from "react";

import styles from "./clientedit.module.css";

import { useNavigate } from "react-router-dom";
import { updateClient } from "../services/auth-service/clientAccountService";
import AlertMessage from "../components/shared/alertMessage/AlertMessage";
import { ADMIN_PATH } from './route_admin';
import { categories } from "../services/common/constants";

const Editprofile = ({ initialValues, onClose }) => {
  const navigate = useNavigate();
  // const categories = ["Lifestyle"," Health & Fitness", "Food & Nutrition","Fashion","Author/Literature","Spiritual & Motivational",
  //   "Education","Career Coach", "Technology" , "Live spaces – Interiors", "Travel & Tourism", "Sports","Professional"
  //    , "Adventure", "Astrology", "Photographer", "Others"]; 
  const [values, setValues] = useState(initialValues);
  const [alertData, setAlertData] = React.useState(null);

  const handleChange = (key, newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };
  const handleRadioChange = (newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      gender: newValue,
    }));
  };
  const handleDropdownChange = (newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      category: newValue,
    }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await updateClient(values);
      if (!res.status === 200) {
        setAlertData({
          message: "Failed to get Response",
        });
      } else if (res === 401) {
        setAlertData({
          message: "User not found or no changes made",
        });
      } else {
        setAlertData({
          message: "Updated!!",
        });
        navigate(ADMIN_PATH);
      }
      console.log("Saving values:", values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="text-center" style={{ marginTop: "100px" }}>
        <div
          className={`inflanar-supports inflanar-profile-info mg-top-100 overlay ${styles.card}`}
        >
          <div className="inflanar-signin__inner">
            <span
              className={`btn btn-outline-dark close-btn ${styles.closebutton} ml-2`}
              onClick={onClose}
            >
              &times;
            </span>
            <form onSubmit={handleSubmit} style={{ textAlign : "left" }}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="form-group inflanar-form-input mg-top-20">
                    <label>First Name*</label>
                    <input
                      className="ecom-wc__form-input"
                      type="text"
                      name="firstName"
                      required="required"
                      autoComplete="off"
                      placeholder="firstName"
                      id={`firstName`}
                      value={values.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="form-group inflanar-form-input mg-top-20">
                    <label>Last Name*</label>
                    <input
                      className="ecom-wc__form-input"
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      required="required"
                      placeholder="lastName"
                      id={`lastName`}
                      value={values.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-4">
                  <div className={`form-group mg-top-20`}>
                    <label className={`${styles.radio_label} mb-0`}>
                      <input
                        type="radio"
                        value="Male"
                        name="gender"
                        id="radio-Male"
                        checked={values.gender === "Male"}
                        onChange={() => handleRadioChange("Male")}
                      />
                      <span className={styles.radio_text}>Male</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-4 p-0">
                  <div className={`form-group mg-top-20`}>
                    <label className={`${styles.radio_label} mb-0`}>
                      <input
                        type="radio"
                        value="Female"
                        name="gender"
                        id="radio-Female"
                        checked={values.gender === "Female"}
                        onChange={() => handleRadioChange("Female")}
                      />
                      <span className={styles.radio_text}>Female</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-4">
                  <div className={`form-group mg-top-20`}>
                    <label className={`${styles.radio_label} mb-0`}>
                      <input
                        type="radio"
                        value="No Gender"
                        name="gender"
                        id="radio-NoGender"
                        checked={values.gender === "No Gender"}
                        onChange={() => handleRadioChange("No Gender")}
                      />
                      <span className={styles.radio_text}>Skip</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="form-group inflanar-form-input mg-top-20">
                    <label>Email*</label>
                    <input
                      className="ecom-wc__form-input"
                      type="email"
                      autoComplete="off"
                      name="email"
                      required="required"
                      placeholder="email address"
                      id={`lastName`}
                      value={values.email}
                      disabled
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="form-group inflanar-form-input mg-top-20">
                    <label>Phone*</label>
                    <input
                      className="ecom-wc__form-input"
                      type="Number"
                      name="phone"
                      required="required"
                      // value={userRegistration.phone}
                      // onChange={handleinput}
                      autoComplete="off"
                      placeholder="Phone"
                      id={`phone`}
                      value={values.phone}
                      disabled
                      onChange={(e) => handleChange("phone", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12"></div>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="form-group inflanar-form-input mg-top-20">
                    <label>Category</label>
                    <select
                      id="dropdown"
                      value={values.category}
                      onChange={(e) => handleDropdownChange(e.target.value)}
                    >
                      <option value="">Select a category</option>
                      {categories.map((option) => (
                        <option key={option} name="category" value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mg-top-40">
                    <button type="submit" className="inflanar-btn">
                      <span>Update Account</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {alertData && (
        <div className="overlay">
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  );
};

export default Editprofile;
