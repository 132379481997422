import React,{useRef,useEffect} from 'react'

const TermsAndCondition = () => {

    const termsRef = useRef(null);

  useEffect(() => {
      // Scroll to the top of the terms section when the component is mounted
      if (termsRef.current) {
          termsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }, []);

  return (
<section className="inflanar-bg-cover pd-top-20 pd-btm-40" ref={termsRef}>
<div className="container">
<div className="row">
<div className="col-12 text-justify">

<div className="inflanar-page-content">
<h3 style={{textAlign:"left"}}>Terms and Conditions ("Terms")</h3>
<p>

IN KORERO provides YOU the platform that allows Our Clients(&quot;User&quot;) to interact with Influencers and/or professionals
(hereinafter referred to as &quot;Influencer&quot;), for content of mutual interest on one-on-one basis (single) or as a group. These
Terms govern Your use of Our service.
<br /><br />
This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder pertaining to
electronic records in various statutes as amended by the Information Technology Act, 2000.
IN KORERO and IN KORERO platform has a website and application*, which are owned, operated and maintained by
CASAASSURE LLP.<br /><br />
<strong>PLEASE READ THE TERMS CAREFULLY BEFORE USE OF THE IN KORERO PLATFORM. THESE TERMS
CONSTITUTE A LEGAL AND BINDING AGREEMENT BETWEEN YOU (THE USER AND/OR INFLUENCER
OF IN KORERO PLATFORM) AND CASAASSURE LLP (THE OWNER OF IN KORERO PLATFORM), AND
GOVERN YOUR USE OF THE IN KORERO PLATFORM. BY CHECKING/CLICKING ON THE `I AGREE`
LINK AND/OR BY DOWNLOADING AND/OR INSTALLING AND/OR BROWSING OR USING IN KORERO
PLATFORM, YOU EXPRESSLY ACCEPT THE TERMS. IF YOU DO NOT ACCEPT THE TERMS, PLEASE DO
NOT BROWSE AND/OR DOWNLOAD AND/OR INSTALL AND/OR USE IN KORERO PLATFORM.</strong><br /><br />
YOU agree and grant permission to IN KORERO to send notifications/information to Your registered email, mobile number
(SMS*and/or WhatsApp*). In case YOU wish to use Your INSTAGRAM/
X(TWITTER)/FACEBOOK/YOUTUBE/LINKEDIN Account and/or Your any other social media account YOU agree and
grant permission to IN KORERO to send appropriate messages by IN KORERO for effective communication.
For the purpose of these Terms and Conditions, the terms &quot;YOU&quot;, &quot;You&quot;, &quot;Your&quot;, &quot;YOUR&quot;, or &quot;User&quot; or &quot;Participants&quot;,
&quot;Participant&quot;, or &quot;Influencer&quot;, or any similar terminology having similar meaning shall mean any person, natural or legal
including their representatives, consultants, advisors, permitted assigns who uses or browses IN KORERO. For the
avoidance of doubt, the term &quot;YOU&quot;, &quot;You&quot;, &quot;Your&quot;, &quot;YOUR&quot; shall include &quot;User&quot; and/or &quot;Influencer&quot;, as the case may be.
&quot;User&quot;, or &quot;Client&quot; or &quot;Users&quot; or &quot;Clients&quot; shall include end user(s) and their representatives who shall be browsing the IN
KORERO platform and interacting with Influencers. &quot;Influencer&quot; and/or &quot;Influencers&quot; includes professional(s) or any
experts in their field (including their representatives) who are intending to conduct a Session with the User. The term &quot;We&quot;,
&quot;Us&quot;, &quot;Our&quot; shall mean IN KORERO and/or IN KORERO Management.<br /><br />
Interpretation of the Terms and Conditions and Privacy policy is as per the understanding of IN KORERO.<br /><br />
<strong>DEFINITIONS</strong>
<br></br>
Certain words in these Terms have a specific meaning and are explained below for YOUR easy understanding:<br /><br />
1.IN KORERO application/ IN KORERO platform website/IN KORERO website /IN KORERO platform means the &quot;IN
KORERO platform&quot; owned by CASAASSURE LLP to provide the Content and IN KORERO Content on Devices, which is
available on various platforms such as iOS, android and supported web browsers etc.<br></br>
2.IN KORERO Management used in these Terms refers to IN KORERO owned by CASAASSURE LLP.<br></br>
3. &quot;Content&quot; means all conversations, text, graphics, images, music, software, audio, video, personal information, Your
social media profile, information, or any other materials used by the User and/or the Influencer during the Session and/or
while accessing the IN KORERO platform. The information and personal information together shall be referred as
&quot;Information&quot; and &quot;Personal Information&quot; and they shall constitute an integral part of the Content. However, it is clarified
that at no point in time will IN KORERO shall be responsible for any of the Content including while being discussed during
the Session on the IN KORERO platform between the User and the Influencer. Further, it is clarified that IN KORERO
shall not be the owner of the Content at any point in time including its availability, upload, discussion during the Session or
in any manner whatsoever.<br></br>
4. &quot;Meeting&quot; or &quot;Session&quot; or any similar terminology used in these Terms and the same have been used interchangeably
means an interactive session between the User and Influencer.<br></br>
5. &quot;Meeting Request&quot; or &quot;Session Request&quot; or any similar terminology used in these Terms and the same have been used
interchangeably means meeting request initiated by the User for the interacting with the Influencer.<br></br>
6. &quot;Device&quot; means any and all devices including internet enabled devices like smart phones, tablets, laptops, desktops, etc.
which are compatible with and have access to the Internet<br></br>
7. &quot;Internet&quot; shall mean the system making use of the TCP/IP software protocols known as the internet or the worldwide web
whatever the communications links may be which connects the User and/or Influencer (including by way of fixed, mobile,
DSL, ISDN, UMTS Wi Max or other broadband links) including any developments in such protocols or any other protocols
which may be developed which give equivalent, reduced or enhanced functionality compared with such protocols.
<br></br>8. &quot;IN KORERO Content&quot; means the logo, trademark, software code, the name, the idea, including the business idea, text,
graphics, the concept or anything in this regard created for IN KORERO platform and owned by IN KORERO.
<br /><br /><strong>ELIGIBILITY</strong><br /><br />
Unless otherwise specified, IN KORERO platform is available for individuals who have attained the age of majority in their
jurisdiction. In the case of India, this service is restricted to Users and/or Influencer who are aged 18 years or older. If YOU
are under the relevant age of majority in Your jurisdiction, You may only access the IN KORERO platform and the services
only in accordance with applicable law which enables a minor in Your jurisdiction to access such a service. In India, by
browsing or downloading and/or installing and/or using the IN KORERO platform, YOU represent and warrant that YOU
are 18 years of age or older. In case YOU are less than 18 years of age and more than 13 years of age, YOU have taken
consent of YOUR parent or guardian.<br /><br />Your access to and use of the IN KORERO platform is subject to these Terms and Conditions, Privacy Policy and all
applicable laws, rules, and regulations.
<br /><br /><strong>REGISTERED USERS AND REGISTERED INFLUENCERS - REGISTRATION PROCESS</strong>
<br /><br />YOU may sign up YOURSELF as a User and/or Influencer of IN KORERO platform and become a registered User and/or
Influencer of the IN KORERO platform (&quot;Registered User&quot; and/or &quot;Registered Influencer&quot;). Email ID used during sign up is
the unique identifier for the Users and/or Influencer. As Registered User and/or Registered Influencer, YOU agree to provide
accurate, current and complete Information during the registration process and to update such Information to keep it
accurate, current and complete. IN KORERO reserves the right to suspend or terminate YOUR registration as Registered
User and/or Registered Influencer without assigning any reason (including for provision of inaccurate, not current or
incomplete Information during the registration process or thereafter). As Registered User and/or Registered Influencer, YOU
shall be responsible for safeguarding YOUR password and for all transactions undertaken using YOUR Username and
password. YOU agree not to disclose YOUR password to any third-party and to take sole responsibility for any activities or
actions under YOUR account, whether or not YOU have authorized such activities or actions. It is YOUR sole responsibility
to change YOUR password immediately if YOU believe that YOUR password has been compromised. IN KORERO will
not be responsible for any financial loss, inconvenience.
<br /><br />IN KORERO shall have the right, but not the obligation, to refrain from providing to YOU any Content in the interest of
national security or in the event of emergency / war or similar situation or if the Content is anti-national, promotes political /
religious propaganda, is against public policy, is banned or restricted from being distributed under any applicable law or
otherwise determine that it is objectionable or obscene or is derogatory to any person or class of persons, hurts the religious
sentiments of any religious group or infringes the privacy rights of any individual(s) or is not in the interest of IN KORERO
platform Users or Influencer or the general public. 
<br /><br />IN KORERO platform has separate sign up (Registration) process for Influencers and Users.
<br /><br /><strong>SIGN UP/SIGN IN AS INFLUENCER</strong>
<br /><br />Influencer sign up/sign in process has a dedicated page where YOU need to fill the mandatory fields, accept Terms and
Conditions and Privacy Policy. Mandatory field which are required but not limited to are first name, last name, email ID,
mobile, gender, category of Influencer, social media account handles (Instagram, X(Twitter), Facebook, YouTube, LinkedIn),
password. After sign up, Influencer can sign in using email ID and password. The Influencer will be able to sign-in on the
platform however YOUR account will be activated after verification of YOUR email ID and YOUR social media accounts
verification. For verification of email ID and social media accounts, IN KORERO will share OTP and guidance on YOUR
email and/or mobile* which YOU will need to use for verification. IN KORERO management may reach out to YOU for
finalizing the Influencer fee. IN KORERO follows different fee for a single Session and group Session. IN KORERO
management can time to time reach out to Influencer to revise the Influencer fees. Influencer will follow the IN KORERO
Session booking and conduction process for a single and group Sessions. Influencer would need to provide free slots in the
form of calendar which Clients can book to have a Session with Influencer. Besides a single Session, Influencer can also
open the calendar and number of seats for group Session as per the IN KORERO Session booking and conduction process.
Once a Client books a Session with YOU, he/she will pay Influencer fee (inclusive of GST and taxes), platform fee and GST
on platform fee. Platform fee is inclusive of payment gateway transaction charges. Platform fee and GST on Platform fee are
non-refundable fees. GST and taxes on Influencer fee are to be paid by the Influencer. YOU agree to follow the payment
process set by IN KORERO. Influencer fee will be payable to YOU within 15 business days after marking the Session
complete on the IN KORERO platform and IN KORERO has verified the completeness of the Session. In case the Session
could not be conducted by the Influencer due to unforeseen circumstances or the Meeting Request is declined by the
Influencer or Meeting Request is auto declined then IN KORERO management holds the right to take appropriate action on
payment of influencer fee which may include refund to the Client. Influencer would need to accept the Meeting Request
within 48 hours as per IN KORERO Session booking process whenever a Client books Influencer’s calendar slot. Influencer
will get the order summary after the payment is made by the Client. Influencer will need to sign in to IN KORERO platform
to accept or decline the Meeting Request by the Client. In case the Meeting Request is not acted upon by the Influencer
within 48 hours it will be auto declined. Session will be conducted upon acceptance of a Meeting Request. Meeting invite
will be sent by IN KORERO only after Influencer accepts the Meeting Request on the IN KORERO platform. Client will be
informed by email by IN KORERO in case Influencer declines the Meeting Request or Meeting Request is auto declined.
<br /><br /><strong>SIGN UP/SIGN IN AS CLIENT</strong>
<br /><br />If YOU are going to sign up/sign in as a Client, the dedicated page is called ‘User’ on the platform where YOU need to fill
the mandatory fields, accept Terms and Conditions and Privacy Policy. Mandatory field which are required but not limited to
are first name, last name, email ID, mobile, gender, category of interest, password. Upon the sign-up, the Client will be able
to sign-in on the platform however YOUR account will be activated after verification of YOUR email ID. For verification of
email ID, IN KORERO will share OTP on YOUR email and/or mobile which YOU will need to use for verification. After
activation of YOUR account, YOU can book the Sessions with Influencers. YOU will need to follow the IN KORERO
Session booking, Session conduction and payment process.IN KORERO follows different fee for a single Session and group
Session. While booking the Session with Influencer, YOU will be charged Influencer fee (inclusive of GST and taxes),
Platform fee and GST on platform fee. Platform fee is inclusive of payment gateway transaction charges. Platform fee and
GST on Platform fee are non-refundable fees. Influencer fee is paid to the Influencer as per the IN KORERO Session
booking and payment process. Session can be booked only after making the payment. While booking the Session Client is
required to indicate his/her name, purpose of the Session and any one of his/her social media identity. Providing these details
will facilitate the Influencer to see Your profile and will improve chances of Influencer accepting Your Meeting Request.
<br /><br />Once the Session is booked Client will receive order summary. Client will have to wait up to 48 hours for Influencer to
accept or decline the Meeting Request after which Meeting Request is auto declined. Meeting invite is sent by IN KORERO
once the Meeting Request is accepted by the Influencer. In case the Meeting Request is declined or auto declined the Client
will receive a Meeting Request decline intimation followed by refund of Influencer fee as per IN KORERO refund process.
IN KORERO may time to time bring promotion offers which may reflect in order summary while making payment.
<br /><br /><strong>VARIOUS PROCESS(S) FOR THE SESSION</strong>
<br /><br />A. Session booking process
<br /><br />1.IN KORERO Clients can easily access details of Influencers like their availability, charges for time slots etc for different
variety of Influencers available on IN KORERO platform.
After sign in on the IN KORERO platform, YOU will see options for Session booking with the Influencers and payment
options as well as confirmation later thereof by the Influencer.
<br /><br />2.The transaction for booking the Session with Influencers is arranged as per free time slots provided by the Influencers to
the IN KORERO platform in form of a calendar. It will be possible if time slot being booked by the Client is free with the
Influencers and is mutually convenient. It is therefore recommended to book time slot in advance on IN KORERO platform.
<br /><br />3.Influncer can open the slots for Session booking by marking the calendar 72 hours in advance. Users can book the
available slots of Influencer 72 hours in advance.
<br /><br />4. Session can be booked only after making the payment. While booking the Session Client is required to indicate his/her
name, purpose of the Session and any one of his/her social media identity. Providing these details will facilitate the
Influencer to see Your profile and will improve chances of Influencer accepting Your Meeting Request. Once the Session is
booked Client will receive order summary. Client will have to wait up to 48 hours for Influencer to accept or decline the
Meeting Request after which Meeting Request is auto declined. Meeting invite is sent by IN KORERO once the Meeting
Request is accepted by the Influencer. In case the Meeting Request is declined or auto declined the Client will receive a
Meeting Request decline intimation followed by refund of Influencer fees as per IN KORERO refund process.
<br /><br />
5. IN KORERO platform has a single Session and group Session. A single Session will have only 1 Client whereas group
Session will have more than one Client in the Session with the Influencer. The group Session will be organized by the
Influencer and he/she shall decide the number of Participants in the Session as well the fees per Session.<br /><br />
<strong>B. Payment process for booking the Session by the Client</strong>
<br />1.Once YOU (Client) have clicked the button for booking the Session, YOU will be presented with multiple payment
options from the payment gateway service provider. The payment options for IN KORERO are UPI, Credit/Debit Card, Net
Banking and Wallet. As a pay-per-Session, additional Session time will require further purchase and is subject to
convenience of Influencers. The transaction with IN KORERO will be non-refundable, non-transferrable and cannot be
carried forward for any reason once the Session time validity ends. Each transaction is unique to individual Client and
cannot be shared with any other person.
<br />2.An order summary email will be sent to both the Influencer and the Client after a successful payment transaction. Order
summary email to the Influencer directs him/her to act upon the Meeting Request created on IN KORERO platform for the
Session.<br />3. Payment includes Influencer fee (inclusive of GST and taxes), platform fee and GST on platform fee. IN KORERO
platform fee is the fee which CASA Assure LLP charges for providing infrastructure and service for managing the IN
KORERO platform. The is inclusive of GST, Taxes and payment gateway charges. The IN KORERO holds the right to
increase or decrease the IN KORERO platform fee without prior notice. Platform fee and GST on Platform fee are non-
refundable fees. GST and taxes on Influencer fee are to be paid by the Influencer.<br />4. Influencer fee and Platform fee may change at the discretion of IN KORERO management. Consequent to amendment the
changes shall reflect on the IN KORERO platform.<br />5. IN KORERO platform uses third-party payment gateway provider(s) who charge transaction fee as per their terms of use,
privacy policy and other relevant policies. Client is required to follow the terms of use, privacy policy and other relevant
policies of such third-party providers. Third-party terms of use, privacy policy and other relevant policies can be accessed on
their website and/or application.<br /><br></br>
<strong>C. Session conduction process</strong><br />1.Once the Influencer accepts the Meeting Request on the IN KORERO platform, it will be visible on the IN KORERO
platform and the Meeting invite for the Session will be sent by email to the Client. It may take up to 48 hours for Influencer
to act upon the Meeting Request for the Session. In case Influencer declines the Meeting Request or the Influencer is not
able to act to accept or decline the Meeting Request within 48 hours the Meeting Request will be auto declined, in such
circumstances an email regarding the same shall be sent to the Client. This will inform YOU about the Meeting Request
validity. This Information will also be visible on IN KORERO platform and application.<br />2.Meeting invite for the Session is sent by IN KORERO only after Influencer accepts the Meeting Request on IN KORERO
platform. The options to accept, decline or mark tentative indicated in the Meeting invite email therefore doesn’t hold any
meaning moreover Session once scheduled can’t be rescheduled or cancelled. Therefore, this option in the Meeting invite
email is not monitored by the IN KORERO management.<br />3. Session can be held only on the registered Device from which transaction has been made. YOU can start the Session only
if YOU have logged in using the right credentials and Your time slot validity has not expired. The start time and end time
will remain unchanged. Further the Session can be audio and video enabled or audio only, the discretion in this regard shall be with the Influencer. For the avoidance of doubt, 
it is hereby clarified that IN KORERO does not bear any responsibility, liability of whatsoever nature in this regard.
<br></br>4.The Session will be held for the fixed pre-booked time slot and will last for pre-selected duration only. YOU cannot pause
and resume the Session multiple times within time slot validity. In special circumstances due to reasons beyond Influencer’s
control, the Influencer can request for a reschedule of the Session prior to the fixed time slot which a Client has to accept.
<br></br>5.Once the time validity of the Session has passed, the Influencer must mark the Session complete on the IN KORERO
platform only after conducting the Session. This action will update the Session status to completed for the Influencer, and IN
KORERO will initiate the payment process for the Influencer. For the Client, once the time validity has passed, the status of
the Session will be displayed as completed, and no further action is expected from the Client. Influencer must not mark the
Session as complete If the Influencer could not conduct the Session, in such cases Client has to contact IN KORERO to
request a refund of the Influencer fee paid.<br></br>6.IN KORERO platform Session with Influencers will not be available for download or offline viewing.
Recording/transmitting the Content in any manner is strictly prohibited and will make YOU liable for stringent legal action.
Recording and uploading of Content as -is and/or after edits on internet or social media platforms is not permitted. Influencer
and/or Clients and their affiliates will not record the Session. Content as-is and/or after edits can’t be uploaded by any party
on internet, social media platforms etc without prior permission from IN KORERO.<br></br>7.Session’s time validity will be considered as per Indian Standard Time. The Session will be single admit use only unless
otherwise specified by IN KORERO. In case a Participant is found to be misbehaving by use of inappropriate words, actions
or any similar means then IN KORERO and/or Influencer holds the rights to remove such miscreant from the Session.
<br></br>8.In case of group Session, the Clients in the Session may not know each other and should maintain the decorum during the
Session. The Session booking, Session conduction and payment process for individual Clients shall remain same as for a
single Session.<br></br>9.Sessions are conducted using a third-party service provider. YOU are required to follow the terms of use, privacy policy
and other relevant policies of such third-party providers. Third-party terms of use, privacy policy and other relevant policies
can be accessed on their website and/or application.<br></br>
10.IN KORERO, as the organizer of the Sessions, can join any Session at their discretion.
<br></br>
<br /><br />
<strong>D. Cancellation and Refund process</strong><br /><br />
1.Once YOU have booked a Session it cannot be cancelled or rescheduled, the amount cannot be refunded or redeemed
against any other benefit.<br></br>
2.There will not be any refund in case of no show by the Client. Client can’t reschedule/cancel the Session irrespective of
Meeting Request acceptance status by the Influencer. Client is entitled to get the refund in case it is found by IN KORERO
that the Session has not been conducted by the Influencer or Meeting Request has been declined by the Influencer or
Meeting Request has been auto declined. In such case Client will get the refund of the Influencer fee in 15 business days in
the same mode of payment. The Client shall get a refund of the fee paid after deducting the platform fee as well as the GST
and taxes on the platform fee, however the Influencer fee shall not be deducted. It is hereby clarified that IN KORERO
platform fee and GST and Taxes for the platform fee are non-refundable in any circumstances.In case of no show by the Influencer, 
the Client must contact IN KORERO within 48 hours from the Session completion time to submit refund claim for verification; after 48 hours, 
no such claims will be entertained. In all cases discretion of
refund vests with IN KORERO.<br></br>
3. Once the Participants have joined the Session, IN KORERO considers the Session to have taken place and waits for the
Influencer to mark it as completed, irrespective of the Session&#39;s duration. IN KORERO is not liable for any refund or partial
refund if any of the Participants leave the Session early.
<br></br><br></br>
<strong>E. Payment process for Influencer</strong>
<br></br>
1.Influencer is entitled to get Influencer fee. The fee shall be mutually agreed between Influencer and IN KORERO
management. Time to time the fee shall be subject to revision after mutual discussion. The Influencer fee is different for a
single Session and group Session.
<br></br>
2. The Influencer fee payment will be remitted to Influencer within 15 business days once it is confirmed by the Influencer
that Session has been mark complete and all the necessary details are provided by the Influencer to IN KORERO for making
the payment. Subsequently, IN KORERO will verify the Session attendance record before initiating payment for the
Influencer. If there is a discrepancy in the Session attendance record and it is found by IN KORERO that the Influencer did
not conduct the Session, then in that case IN KORERO will not make the payment of the Influencer fee. The discretion to
refund the Influencer fee to the Client or to pay the Influencer fee to the Influencer vests with IN KORERO in all cases of
discrepancies between the Session attendance record and the Session Participants.
<br></br>
3.The mode of payment to Influencer will be decided mutually between Influencer and IN KORERO. In special
circumstances, the payments specified in Influencer onboarding agreement will supersede this clause. Influencer fee is
inclusive of GST and Taxes at Influencer’s end and he/she is liable to take necessary action in this regard.
<br></br>
<br></br>
<strong>YOUR RESPONSIBILITIES</strong>
<br></br>
a. YOU will be solely responsible for obtaining and maintaining the Device and Internet connection needed in order to
access and use IN KORERO platform website/application and paying for all such charges in relation thereto. IN KORERO
platform uses third-party Session conduction and video conferencing platform for the Session therefore, YOU will need to
comply to their terms of use, privacy policy and other relevant policies.
<br></br>
IN KORERO platform website/application is compatible on selected operating systems and specific versions and Device(s).
The Session booking process of the IN KORERO Platform shall be subject to the operating system of YOUR Device(s).
YOU need to have a Device connected with Internet to begin and complete. IN KORERO shall not be responsible in case of
any fluctuation in the internet connection speed leading to corruption of application file download or any delayed or
defective procedure of the application on Your Device(s). IN KORERO shall not be responsible or liable to YOU for
interruption, disruption, deactivation in Session on account of any Force Majeure Event. For the purpose of these Terms and
Conditions, “Force Majeure Event” shall mean any event beyond the reasonable control of IN KORERO including but not
limited to act of God, any act or omission of government or quasi-government agencies or lock out, strike, curfew, technical
errors etc. IN KORERO may, at its sole discretion, make bug fixes, updates for the IN KORERO platform. In the event IN
KORERO has upgraded the IN KORERO Platform or any features thereof, YOU will be required to update Your Device in
order to make the Device compatible with such upgrades. IN KORERO shall not be responsible or liable to YOU in any
event including but not limited to YOU being unable to access IN KORERO platform or booking a Session due to Your
failure to upgrade Your Device.
<br></br>
The IN KORERO Platform is protected by copyright, trademark, patent, trade secret and other laws and shall be used as
provided in these Terms and Conditions, without written permission of CASAASSURE LLP.
<br></br>
b. In a UPI , Credit card and/or Debit card and/or Net banking and/or Cash card transaction and/or any other mode available,
YOU must use YOUR own UPI account, credit card and/or debit card and/or net banking account and/or cash card. YOU
confirm and acknowledge that YOU are aware of the fact that when making any online payment through such modes or via
any other mode available, YOU may be directed to an external payment gateway page. The payment gateway may redirect
YOU to other website(s)/app(s) maintained or controlled by third parties, and IN KORERO does not control such third-party
website(s)/app(s) and hence are not responsible for any transactions on such website(s)/app(s). IN KORERO will not be
liable for any UPI, Credit card or Debit card or Net banking or Cash card fraud, and IN KORERO will not entertain or
address any such grievances or issues. YOU are requested to communicate all grievances related to such issues to YOUR
bank or mobile carrier/operator or mobile wallet provider who has enabled such transaction. IN KORERO shall not be
responsible for all or any dispute or difference relating to online payment made by YOU through credit card or debit card or
net banking account or cash card or via any other mode available. Further, IN KORERO will not be responsible for any
financial loss, inconvenience or mental agony resulting from misuse of YOUR UPI account, credit card or debit card or net
banking account number or cash card and other details. Payments once made via any mode available shall not be refunded in
any circumstances. YOU are hereby advised to keep details of Your UPI account, credit/debit card and net banking etc
confidential and do not share any such details with any other third-party.
<br></br>
The IN KORERO platform may be linked to the website of third parties, affiliates and business partners. IN KORERO has
no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such websites or made
available by/through Our IN KORERO platform. Inclusion of any link on the IN KORERO platform does not imply that IN
KORERO endorses the linked site. Users and the Influencers may use the links at their own risk.
<br></br><br></br>
<strong>CONTENT AND IN KORERO CONTENT</strong>
<br></br>
The contents listed on the IN KORERO platform are (i) Content which are User and/or Influencer generated content, or (ii)
IN KORERO Content which belongs to IN KORERO.
<br></br>
The Content that is collected by IN KORERO directly or indirectly from the Users and/or the Influencers shall belong to
them and YOU agree to allow IN KORERO to edit and/or publish or delete such Information as IN KORERO deems fit on
the IN KORERO platform or in any manner whatsoever.
<br></br>
The contents of the IN KORERO platform including the Information, text, graphics, images, logos, button icons, software
code, design, and the collection, arrangement and assembly of content on the IN KORERO platform (collectively, &quot;IN
KORERO Content&quot;), are the property of IN KORERO and are protected under copyright, trademark and other laws. User
and/or Influencer shall not modify the IN KORERO Content or reproduce, display, publicly perform, distribute, or otherwise
use the IN KORERO Content in any way for any public or commercial purpose or for personal gain.
<br></br>
The IN KORERO Content on IN KORERO platform and the IN KORERO/CASAASSURE LLP word mark and the IN
KORERO/CASAASSURELLP design mark, as well as certain other of the names, logos, and materials displayed on or
through IN KORERO platform that constitute trademarks, trade-names, service marks or logos (&quot;Marks&quot;) are owned by or
licensed to CASAASSURE LLP and are subject to copyright, trademark, and other intellectual property rights under Indian
laws. YOU agree not to reproduce, duplicate, copy, download, stream capture, archive, upload, publish, broadcast, sell,
resell, modify, translate, decompile, disassemble, reverse engineer or exploit for any purposes or any portion of the IN
KORERO platform, including, without limitation, the IN KORERO Content and the Marks, except as authorized by these
Terms or as otherwise authorized in writing by CASAASSURE LLP. In addition, YOU are strictly prohibited from creating
derivative works, or materials that otherwise are derived from or based on in any way the IN KORERO Content and the
Marks, including montages, mash-ups and similar videos, wallpaper, desktop themes, greeting cards, and merchandise,
except as authorized by these Terms or as otherwise authorized in writing by CASAASSURE LLP. YOU must abide by all
copyright notices, information, and restrictions contained in or associated with any IN KORERO Content. YOU must not remove, alter, interfere with, or circumvent any copyright, trademark, or other proprietary notices marked on the IN
KORERO Content or any digital rights management mechanism, Device or other content protection or access control
measure (including, without limitation, geo-filtering and/or encryption) associated with the IN KORERO Content.
<br></br>Copying of the copyrighted IN KORERO Content published by IN KORERO on the IN KORERO platform for any
commercial purpose or for the purpose of earning profit or for personal gain will be a violation of copyright and IN
KORERO reserves its rights under applicable law accordingly.<br></br>
IN KORERO ensures easy access to the Content and IN KORERO Content to Users and Influencer by providing various
feature to update Your profile Information and other Content relevant for the using IN KORERO platform. However, IN
KORERO takes the independent decision whether to edit and/or publish or reject the Content submitted by You. You hereby
represent and warrant that You are fully entitled under law to including but not limited to upload, discuss the Content as part
of Your profile, Session or otherwise while using IN KORERO platform, and that no such Content breaches any third-party
rights, including intellectual property rights. Upon becoming aware of a breach of the foregoing representation, IN
KORERO may modify or delete parts of Your profile Information at its sole discretion with or without notice to You.
Further, YOU agree that in case IN KORERO intentionally and unintentionally deletes, commits an error, edits in YOUR
Content, it shall not be liable in any manner whatsoever.<br></br><br></br>
IN KORERO authorizes YOU to view and access the Content and IN KORERO Content available on or from the IN
KORERO platform solely for Session booking only as per this Terms and Conditions.
<br></br>
User and the Influencer shall explicitly agree that IN KORERO reserves the right to publish the Content provided by User
and/or the Influencer to a third-party including content platforms.
<br></br>
IN KORERO does not guarantee the accuracy or completeness of any Content or Information provided by Users and the
Influencers on the IN KORERO platform. To the fullest extent permitted by law, IN KORERO disclaims all liability arising
out of the User’s and Influencer’s use or reliance upon the IN KORERO platform, representations and warranties made by
other Users and the Influencers, the Content or Information provided by the Users and the Influencers on the IN KORERO
platform, or any opinion or suggestion given or expressed by IN KORERO or any User and Influencer in relation to any
User and/or Influencer or any Sessions conducted between the Users and the Influencers.
<br></br>
User and/or Influencer acknowledges that they are the original authors and creators of any Content uploaded by them on
and/or via IN KORERO platform and that no Content uploaded by them would constitute infringement of the intellectual
property rights of any other person. IN KORERO reserves the right to remove any Content which it may determine at its
own discretion as violating the intellectual property rights of any other person, including but not limited to patent, trademark,
copyright or other proprietary rights. User and/or Influencer agree to absolve IN KORERO from and indemnify IN
KORERO against all claims that may arise as a result of any third-party intellectual property right claim that may arise from
YOU uploading of any Content on the IN KORERO platform. YOU may not use the images in IN KORERO for any
purpose other than those directly or indirectly related to the creation and uploading of Content to IN KORERO. The User
and/or Influencer also agree to absolve IN KORERO from and indemnify IN KORERO against all claims that may arise as a
result of any third-party intellectual property claim if YOU download, copy or otherwise utilizes Content from IN KORERO
platform for his/her personal or commercial gain.
<br></br>
IN KORERO shall have the right to edit or remove the Content and any comments in such manner as it may deem fit at any
time.
<br></br>
Users and Influencers shall ensure that the Content or any further responses to the Content (including responses to Users) is
not harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic or libelous in any manner. Further,
YOU should ensure that the Content is not invasive of any other person’s privacy, or otherwise contains any elements that is
hateful, racially or ethnically objectionable, disparaging, or otherwise unlawful in any manner whatever. IN KORERO
reserves the right to remove any Content which it may determine at its own discretion is violative of these Terms and
Conditions or any law or statute in force at the time. Also, YOU agree to absolve IN KORERO from and indemnify IN
KORERO against all claims that may arise as a result of any legal claim arising from the nature of the Content created,
edited, deleted, accessed, discussed, or spoken while registration, browsing, during the Session and/or post Session by the
User and Influencer.<br></br>
Users and Influencers shall ensure that no portion of the Content uploaded on the IN KORERO platform is violative of any
law for the time being in force.<br></br>
IN KORERO may retain Content from Users and/or Influencers on IN KORERO platform for as long as necessary,
depending on the type of Information; purpose, means and modes of usage of such Information; and according to the SPI
Rules, in accordance with the Information Technology Act. Computer web server logs may be preserved as long as
administratively necessary.<br></br>
However, for the avoidance of doubt, the Content created by the User and/or the Influencer including while accessing the IN
KORERO platform and/or during the Session shall not be part of IN KORERO Content and in no event shall IN KORERO
be responsible for the ownership of the same and assumes no responsibility and liability for the Content in any manner
whatsoever.
<br></br><br></br>
<strong>RESTRICTIONS</strong><br></br>
a. It is hereby clarified that IN KORERO Platform is provided for YOUR personal, non-commercial use only. YOU agree
not to, either directly or through the use of any device, software, web-based service, or by other means, copy, download,
archive, perform, display, upload, publish, transmit or retransmit the Content or create any work or material that is derived
from or based on the Content, rent, lease, duplicate, sublicense, assign, pledge, loan, or resell the Content on IN KORERO
platform. YOU shall not translate, reverse engineer, decompile, disassemble IN KORERO platform. YOU shall only use the
IN KORERO platform on Device that is under YOUR exclusive control and ownership. YOU shall not permit any third-
party to benefit from the use or functionality of IN KORERO platform, either directly or via any facility management,timesharing, service bureau or any other arrangement. All rights not expressly set forth hereunder are reserved exclusively
by CASAASSURE LLP.<br></br>
b. YOU are prohibited from using technology or other means to access, index, frame, or link IN KORERO platform, in
whole or in part that is not authorized by CASAASSURE LLP;<br></br>
c. YOU hereby agree not to use IN KORERO platform for promoting services of competitors of CASSAASSURE LLP;<br>
</br>
d. YOU hereby expressly agree to use IN KORERO platform in strict compliance with applicable laws;
<br></br>
e. YOU agree, undertake and confirm that Your use of IN KORERO platform shall be strictly governed by the binding principles listed hereinafter. The Participant shall have the discretion to leave the Session immediately if he/she finds a breach of the binding principles in any manner during the Session and report the incident to IN KORERO. YOU also agree to absolve IN KORERO from and indemnify IN KORERO against all claims that may arise as a result of disputes between the Participants due to the breach of the binding principles. The binding principles include that YOU shall not host, display, upload, modify, publish, transmit, update or share any Content that
<br></br>
i. belongs to another person and to which YOU do not have any right to; or interferes with another user`s use;
<br></br>
ii. is harmful, harassing, blasphemous, defamatory, obscene, pornographic, libellous, invasive of another`s privacy,
hateful, or ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise
unlawful in any manner whatever, or unlawfully threatening or unlawfully harassing including but not limited to &quot;indecent
representation of women&quot; within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986, of another
person;
<br></br>
iii. is misleading in any way; 
<br></br>
iv. is patently offensive to the online community, such as Content that promotes paedophilia, racism, bigotry, or physical
harm of any kind against any group or individual;
<br></br>
v. involves the transmission of &quot;junk mail,&quot; &quot;chain letters,&quot; or unsolicited mass mailing or &quot;spamming&quot;;
<br></br>
vi. infringes upon intellectual property rights of third-party or violates rights of privacy (including without limitation
  unauthorized disclosure of a person`s name, email address, physical address or phone number) or rights of publicity;
  <br></br>
  vii. contains restricted or password-only access pages, or hidden pages or images;
  <br></br>
  viii. provides material that exploits people in a violent or otherwise inappropriate manner or solicits Personal Information
from anyone;
<br></br>
ix. provides instructional information about illegal activities such as making or buying illegal weapons or providing or
creating computer viruses;
<br></br>
x. contains video, photographs, or images of another person without his or her express written consent and permission or the
permission or the consent of his/her guardian in the case of minor;
<br></br>
xi. engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter,
advertising etc. Throughout these Terms and Conditions, Our &quot;prior written consent&quot; means a communication coming from
Our authorized representative, specifically in response to Your request, and specifically addressing the activity or conduct for
which YOU seek authorization;
<br></br>
xii. harm minors in any way;
<br></br>
xiii. violates any law for the time being in force;
<br></br>
xiv. threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public
order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is
insulting to any other nation;
<br></br>
xv. contains software virus, or any other computer code, file or program designed to interrupt, destroy or limit the
functionality of any computer resource;
<br></br>
xvi. is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity
or agency for financial gain or to cause any injury to any person;
<br></br>
xvii. involves bringing unauthorized persons(s) into the Session. YOU shall not forward the Meeting invite to unauthorized person(s). Only the Participants in the Meeting invite sent by 
IN KORERO are authorized to attend the Session. The number and identity of Participants in the Session are fixed; anyone else will be considered unauthorized. The Influencer has the right to leave or end the Session if he/she finds unauthorized person(s) in the Session.
If there is a dispute on the number of Participants in the Session then IN KORERO will check the
attendance record. If it is not possible to verify via the attendance record then IN KORERO will consult the Influencer but in
any case, the decision of IN KORERO will be final.
<br></br>
f. YOU shall not use any &quot;deep-link&quot;, &quot;page-scrape&quot;, &quot;robot&quot;, &quot;spider&quot; or other automatic device, program, algorithm or
methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of IN KORERO
platform or any Content or IN KORERO Content, or in any way reproduce or circumvent the navigational structure or
presentation of IN KORERO platform or any Content or IN KORERO Content, to obtain or attempt to obtain any materials,
documents or Information through any means not purposely made available through IN KORERO platform. We reserve the
right to bar any such activity;
<br></br>
g. YOU shall not probe, scan or test the vulnerability of IN KORERO platform or any network connected to IN KORERO
platform nor breach the security or authentication measures on IN KORERO platform or any network connected to IN
KORERO platform. YOU may not reverse look-up, trace or seek to trace any Information on any other User of or visitor to
IN KORERO platform to its source;
<br></br>
h. YOU agree that YOU will not take any action that imposes an unreasonable or disproportionately large load on the
infrastructure of IN KORERO platform or any systems or networks connected to IN KORERO platform;
<br></br>
i. YOU may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or
transmittal YOU send to Us on or through IN KORERO platform or any service offered on or through IN KORERO
platform. YOU may not pretend that YOU are, or that YOU represent, someone else, or impersonate any other individual or
entity;<br></br>
j. YOU shall not engage in advertising to, or solicitation of, other Users of IN KORERO platform to buy or sell any products
or services, including, but not limited to, services being displayed on or related to IN KORERO platform;
<br></br>
k. Your correspondence or business dealings with, or participation in promotions of, third-party advertisers found on or
through IN KORERO platform, including payment and delivery of goods or services, and any other Terms, conditions,
warranties or representations associated with such dealings, are solely between YOU and such third-party advertiser. We
shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result
of the presence of such third-party advertisers. YOU acknowledge and agree that CASAASSURE LLP is not responsible or
liable for: (i) the availability or accuracy of such website(s)/app(s) or resources or (ii) the content, products, or services on or
available from such website(s)/app(s) or resources. Links to such website(s)/app(s) or resources do not imply any
endorsement by CASAASSURE LLP of such website(s)/app(s) or resources or the content, products, or services available
from such website(s)/app(s) or resources. YOU acknowledge sole responsibility for and assume all risks arising from YOUR
use of any such website(s)/app(s) or resources;
<br></br>
l. It is possible that other Users (including unauthorized Users or &quot;hackers&quot;) may post or transmit offensive or obscene
materials on IN KORERO platform and that YOU may be involuntarily exposed to such offensive and obscene materials. It
also is possible for others to obtain Personal Information about YOU on the public forum due to Your use of IN KORERO
platform, and that the recipient may use such Information to harass or injure YOU. We do not approve of such unauthorized
uses but by using IN KORERO platform, YOU acknowledge and agree that We shall not be responsible for the use of any
Personal Information that YOU publicly disclose or share with others on IN KORERO platform. Please carefully select the
type of Information that YOU publicly disclose or share with others on IN KORERO platform;
<br></br>
m. YOU give IN KORERO express rights and consent to modify and/or display or delete Your comment in relation to the
relevant Content on the IN KORERO platform, including making it available to other members for viewing;
<br></br>
n. IN KORERO is not the owner of any Content of the Session. Ownership of the Content continues to vest with the
originator. IN KORERO reserves the right to remove Your Content on IN KORERO platform at its sole discretion and/or
upon on request of any third-party, that such Content may create liability for IN KORERO or harm the reputation and /or
goodwill of CASAASSURE LLP in any nature;
<br></br>
o. IN KORERO platform is only a platform facilitating a Session between Influencer(s) and Client(s). Therefore, any
disputes arising between them (including their representatives/consultants/advisors/permitted assigns) during the Session
and/or during any interaction between them shall not make IN KORERO platform and it’s management responsible and/or
liable in any manner whatsoever. Further, such disputes if any are to be resolved among the Participants by themselves
without involving IN KORERO management in any fora;
<br></br>
p. If YOU are dissatisfied with IN KORERO platform and/or IN KORERO management and/or it’s services, YOUR sole
remedy is it discontinue using the services of IN KORERO platform;
<br></br>
q. IN KORERO shall not be liable for Your losses caused by any unauthorized use of YOUR account. The User agrees to
absolve IN KORERO from any liability and indemnity and further indemnify IN KORERO against all claims that may arise
as a result of any third-party intellectual property right claim that may rise from the User and/or any usage by the User;
<br></br>
r.IN KORERO reserves the right to make the final decision in case of a conflict. The total aggregate liability with respect to
any claim made here in shall be limited to the last paid platform fee amount.
<br></br><br>
</br>
<strong>TERMINATION</strong>
<br></br>
YOUR (Registered Influencer, Influencer, Client or Registered User) right to use IN KORERO shall automatically terminate
if YOU violate these Terms and Conditions or any Terms, rules or guidelines published in connection with IN KORERO
platform. CASAASSURE LLP reserves the right, in its sole discretion, to suspend or terminate YOUR access to all or any
part of IN KORERO platform, for any reason, with or without notice
<br></br><br></br>
<strong>FEEDBACK</strong>
<br></br>
CASAASSURE LLP welcomes and encourages YOU to provide feedback, comments and suggestions for improvements to
IN KORERO platform (&quot;Feedback&quot;). YOU may submit Feedback by emailing us at <a className='text-primary' href='https://inkorero.com/contactus'>support@inkorero.com</a>. YOU
acknowledge and agree that all Feedback will be the sole and exclusive property of CASAASSURE LLP and YOU hereby
irrevocably assign to CASAASSURE LLP all of YOUR right, title, and interest in and to all Feedback, including without
limitation all worldwide patent rights, copyright rights, trade secret rights, and other proprietary or intellectual property
rights therein. At CASAASSURE LLP request and expense, YOU will execute documents and take such further acts as
CASSAASSURE LLP may reasonably request to assist to acquire, perfect, and maintain its intellectual property rights and
other legal protections for the Feedback. YOU further acknowledge and agree that CASASSURE LLP shall not be under an
obligation to take any action pursuant to the Feedback provided by YOU. CASAASSURE LLP may, at its sole discretion,
decide whether any action is required to be taken based on the Feedback received from YOU. YOU hereby expressly agree
to indemnify and keep CASAASSURE LLP harmless against any liabilities that may suffered or incurred by
CASAASSURE LL P as a consequence of any action taken by CASAASSURE LLP pursuant to Your Feedback.
<br></br>
<br></br>
<strong>WARRANTY DISCLAIMER</strong>
<br></br>

To the maximum extent permitted by applicable law of India, IN KORERO platform is provided on an &quot;as is&quot; basis without
warranty of any kind, express or implied. Without limiting the foregoing provisions, YOU assume sole risk and
responsibility for selecting IN KORERO platform to achieve YOUR intended results, and sole responsibility for the
installation of, use of, and results obtained from IN KORERO platform. Without limiting the foregoing provisions,
CASAASSURE LLP makes no warranty that IN KORERO platform will be error-free, virus free, or free from interruptions
or other failures or that the IN KORERO platform will satisfy YOUR specific requirements or that the errors will be
rectified. YOU understand that IN KORERO platform can be blocked or made inoperable and CASAASSURE LLP assumes
no responsibility or liability for the same. IN KORERO platform is not fault-tolerant and is not designed or intended for use
in hazardous environments requiring fail-safe performance, including without limitation, in the operation of nuclear
facilities, aircraft navigation or communication systems, air traffic control, weapons systems, direct life-support machines, or
any other application in which the failure of network could lead directly to death, personal injury, or severe physical or
property damage (collectively, &quot;High Risk Activities&quot;). CASAASSURE LLP expressly disclaims any express or implied
warranty of fitness for High Risk Activities.
<br></br>
<br></br>
<strong>INDEMNIFICATION</strong>
<br></br>
YOU agree to indemnify, defend, and hold harmless CASAASSURE LLP, its subsidiaries, affiliates, officers, directors,
employees, consultants and agents (“INDEMNIFIED PARTIES”) directly or indirectly from and against any and all claims,
liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys` fees and costs) that such INDEMNIFIED
PARTIES may incur as a result of or arising from (a) any Information (including, without limitation, YOUR Content,
feedback, or any other content) YOU (or anyone using YOUR account) submit, post, or transmit on or through IN KORERO
platform (b) YOUR (or anyone using YOUR account`s) use of the IN KORERO platform (c) YOUR (or anyone using your
account`s) violation of these Terms or (d) YOUR (or anyone using YOUR account`s) violation of any rights of any other
person or entity, including, without limitation, any copyright, patent, trademark, trade secret or other proprietary rights of
any person or entity. CASAASSURE LLP reserves the right, at its own expense, to assume the exclusive defense and control
of any matter otherwise subject to indemnification by YOU, in which event YOU will cooperate with CASAASSURE LLP
in asserting any available defenses.
<br></br>
YOU agrees to absolve CASA ASSURE LLP and IN KORERO from and indemnify CASA ASSURE LLP and IN
KORERO against all claims that may arise directly or indirectly as a result of the YOUR actions or inactions including
resulting from the YOUR use of IN KORERO platform.
<br></br>
<br></br>
<strong>LIMITATION OF LIABILITY</strong>
<br></br>
Under no circumstances directly or indirectly and under no legal theory, whether in tort, contract, and/or otherwise, shall
CASAASSURE LLP be liable to YOU and/or to any other person or entity for any indirect, special, incidental, punitive,
remote or consequential damages and/or any other damages whatsoever (including, without limitation, damages for loss of
profits, loss of goodwill, loss of confidential and/or other Information, business interruption, work stoppage, computer
failure and/or malfunction, personal injury, loss of Privacy, failure to meet any duty including a duty of good faith and/or of
reasonable care, negligence (whether active or passive), and any other pecuniary and/or other loss whatsoever) arising out of
in relation to IN KORERO and/or in any way related to the use and/or inability to use IN KORERO platform , loss of data,
any malware attack, virus attack,cyber attack and/or otherwise under and/or in connection with any provision of these
Terms, even in the event of fault, tort (including negligence, and gross negligence), strict liability, breach of contract, and/or
breach of warranty by CASAASSURE LLP, and even if CASAASSURE LLP has been advised of the possibility of such
damages. In no event will CASAASSURE LLP be liable for any damages in excess of YOUR last paid platform fee amount
for IN KORERO platform.
<br></br><br></br>
<strong>INTERNATIONAL USE</strong>
<br></br>
CASAASSURE LLP is a company based in India. CASSAASSURE LLP objective is to conduct as many Influencer
Session(s) as is legally available in accordance with the applicable laws of India. YOU hereby acknowledge that
CASASSURE LLP is not responsible or liable in any manner to comply with any local laws of YOUR territory except India
with respect to IN KORERO.
<br></br>
<br></br>
<strong>PRIVACY POLICY</strong>
<br></br>
All Information provided by YOU or collected by us shall be governed in accordance with the Privacy Policy located <a className='text-primary' href='https://www.inkorero.com/privacyandpolicy'>www.inkorero.com/privacyandpolicy</a>
<br>
</br><br></br>
<strong>RELATIONSHIP</strong>
<br></br>The relationship between CASAASSURE LLP and YOU is on a principal-to-principal basis. YOU are in no way
CASAASSURE LLP ’s legal representative, partner or agent for any reason whatsoever.
<br></br>
<br></br>
<strong>CONFIDENTIALITY</strong><br></br>
YOU agree that IN KORERO platform, including, but not limited to, the object code components, provided to YOU is
&quot;Confidential Information&quot; of CASAASSURE LLP. YOU shall retain all Confidential Information in strict confidence at
least with the same amount of diligence that YOU exercise in preserving the secrecy of YOUR most-valuable Information,
but in no event less than reasonable diligence.
<br>
</br><br></br>
<strong>ASSIGNMENT</strong><br>
</br>
These Terms are personal to YOU and YOU shall not assign, transfer, sub-contract or otherwise part with these Terms or any
right or obligation under it without CASAASSURE`s prior written consent. Any attempt by YOU to assign or transfer these
Terms, without such written consent, will be null and of no effect. CASAASSURE LLP may assign or transfer these Terms
to any third-party, at its sole discretion, without restriction.
<br>
</br><br></br>
<strong>SEVERABILITY</strong><br>
</br>
If any provision of these Terms is held to be unenforceable, the enforceability of the remaining provisions shall in no way be
affected or impaired thereby.
<br>
</br><br></br>
<strong>WAIVER</strong><br>
</br>
The failure of CASAASSURE LLP to enforce or to exercise at any time or for any period any term of or any right pursuant
to these Terms and Conditions shall not be construed as a waiver of any such right and shall in no way affect
CASAASSURE’s right later to enforce or exercise it.
<br>
</br><br></br>
<strong>ELECTRONIC COMMUNICATION</strong><br>
</br>
When YOU use or send any data, Information or communication to CASAASSURE, YOU agree and understand that YOU
are communicating with CASAASSURE through electronic records and YOU consent to receive communications via
electronic records from CASAASSURE periodically and as and when required. CASAASSURE will communicate with
YOU by email or any push or other message or electronic records on the email address and or mobile number available with
CASAASSURE which will be deemed adequate service of notice / electronic record.
<br>
</br><br></br>
<strong>NOTICE</strong><br>
</br>
Any notices or other communications required will be in writing and emailed to CASAASSURE at <a className='text-primary' href='https://inkorero.com/contactus'>support@inkorero.com</a>. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is
transmitted.
In the event YOU have any complaints with respect to any Content on IN KORERO platform, please write to Us
at <a className='text-primary' href='https://inkorero.com/contactus'>support@inkorero.com</a>. with details of the objectionable Content and Your details including Your name and mobile
number and such other details as may be requested by Us. Based on the complaint raised, We will take reasonable measures
to resolve the issue. YOU hereby expressly agree that CASAASSURE LLP shall under no circumstance shall be liable to
YOU for any damages whatsoever. Further, YOU hereby expressly agree that CASAASSURE makes no representations
under these Terms and Conditions that any complaint raised by YOU will be resolved to Your satisfaction. All steps to be
taken by CASAASSURE in this regard shall be at the sole discretion of CASAASSURE LLP.
<br>
</br><br></br>
<strong>LAW DISPUTES</strong><br>
</br>
These Terms and all matters arising from it are governed by and construed in accordance with the laws of India and courts of
Mumbai, India shall have exclusive jurisdiction over all disputes arising in connection with these Terms.
<br>
</br><br></br>
<strong>ENTIRE AGREEMENT AND AMENDMENT</strong><br>
</br>
These Terms expressly supersedes and completely replaces any and all prior ‘Terms and Conditions’ and anywhere else.
CASAASSURE shall not be bound by or liable to YOU for any pre-existing or contemporaneous written or oral
representations or warranties, made by anyone, with respect to IN KORERO platform, including any authorized agents,
employees, or representatives.
<br></br>
Any change, modification and update regarding the various process(s) for the Session as covered under the Terms
hereinabove shall be reflected on the IN KORERO platform in terms of the operational process; while IN KORERO
endeavours to make the changes accordingly to the Terms for reflecting the modification in terms of the operational changes
in relation to the various process(s) for the Session herein, however, in this regard, the IN KORERO platform shall supersede
the Terms provided herein.<br></br>
CASAASSURE reserves the right, at its sole discretion, to modify the Terms from time to time (“Updated Terms and
  Conditions”). The Updated Terms and Conditions shall be effective immediately and shall supersede these Terms and
  Conditions. CASAASSURE LLP shall not be under an obligation to notify YOU of any changes to the Terms and
  Conditions. YOU shall be solely responsible for reviewing the Terms and Conditions from time to time for any
  modifications. By continuing to use IN KORERO platform after the Updated Terms and Conditions have been published,
  YOU affirm YOUR agreement to the Updated Terms and Conditions.
  <br>
</br><br></br>
<strong>CONTACT</strong><br>
</br>
If YOU have any questions about these Terms and Conditions, please contact <a className='text-primary' href='https://inkorero.com/contactus'>support@inkorero.com</a>
</p>
<br/>
</div>
</div>
</div>
</div>
<span className='ml-3' style={{fontSize:"70%"}}>*Some of Our facilities including the IN KORERO application (app) is under process. The Terms and Conditions for such facilities will be
applicable once such facilities are operational.</span>
</section>
  )
}

export default TermsAndCondition