import React, { useState, useEffect,useCallback } from "react";
import {
  AllSearchRequestDebounce,
  handleUpdateRequestForSessions,
} from "../services/auth-service/requestService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch,faSpinner  } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../components/shared/alertMessage/AlertMessage";
import styles from "./clientedit.module.css";
import { searchInfluencers } from "../services/auth-service/InflAccountService";
import { debounce } from "../services/common/debounce";
const status = ["pending", "accepted", "completed", "decline", "markcomplete"];
const paymentStatus =['Remittance pending','Payment received','Requesting refund','Refunded','Remitted'];


const AllRequests = ({ onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alertData, setAlertData] = React.useState(null);
  const [requests, setRequests] = useState([]);

  const [editedData, setEditedData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);



  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];

    const renderEllipsis = () => <span className="ml-2 mt-2 mb-2">...</span>;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
        // Display first, last, and nearby pages
        buttons.push(
          <button
            className={`btn btn-outline-primary mt-2 ml-2 mb-2 ${
              i === currentPage ? "active" : ""
            }`}
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        );
      } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
        // Display ellipsis if not already displayed
        buttons.push(renderEllipsis());
      }
    }

    return buttons;
  };

  // handleSearch function
  const handleSearch = async (query, page) => {
    try {
      setLoading(true);
      const response = await AllSearchRequestDebounce(query, page);
      setRequests(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query, page) => {
      handleSearch(query, page);
    }, 300),
    []
  );
    // Reset currentPage to 1 whenever searchQuery changes
    useEffect(() => {
      setCurrentPage(1);
    }, [searchQuery]);

  useEffect(() => {
    debouncedSearch(searchQuery, currentPage);
  }, [searchQuery, currentPage, debouncedSearch]);


  const handleEdit = (index) => {
    setEditIndex(index);
    const initialEditedData = requests.map((req) => ({ ...req }));
    setEditedData(initialEditedData);
  };

  const handleSave =async (_id,status,paymentStatus,attendence,utr_no,index) => {
    try{
    const response  =  await handleUpdateRequestForSessions(_id,status,paymentStatus,attendence,utr_no)
    const editedRowData = editedData[index];

    const updatedRequests = [...requests];
    updatedRequests[index] = editedRowData;
    setRequests(updatedRequests);

    setEditIndex(null);
    }catch(e){
      console.log(e);
    }
  };

  // this is in case client requires delete request also.
  // const handleDelete = (index) => {
  //   const updatedRequests = [...requests];
  //   updatedRequests.splice(index, 1);
  //   setRequests(updatedRequests);
  // };

  return (
    <>
      {/* <div className="inflanar-profile-info mg-top-30"> */}
      <div className="container mt-3" style={{overflowY:"auto",maxHeight:"100%"}}>
        <div className="card" style={{overflowX:"auto"}}>
          <span
            className={`btn btn-outline-dark close-btn ${styles.closebutton}`}
            onClick={onClose}
          >
            &times;
          </span>
          <div className="card-header">
            <h2 className="mb-0">
              Edit Session Details
            </h2>
          </div>
            <div className="inflanar-search-form__form">
                <div className="inflanar-search-form__group">
                <div className="inflanar-search-form__icon">
                <FontAwesomeIcon
                                      style={{fontSize: "18px", }}
                                      data-toggle="collapse"
                                      icon={faSearch}
                                    />
                </div>
                <input type="text" placeholder="Search Influencer,User,OrderID,UTR_field,status,payment_status" value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}/>
                </div>
              </div>
              {loading ? (
                // Display spinner while waiting for the response
                <div className="spinner-container card-body">
                <FontAwesomeIcon
                                className="spinner"
                                icon={faSpinner}
                              />
                              </div>
              ) : (
              <div>
          <div className="card-body">
            {/* <div className="card mw-100"> */}
            {/* <div className="table"> */}
            <table className="table table-stripped">
              <thead>
                <tr>
                  <th>User</th>
                  <th>User mail</th>
                  <th>Influencer</th>
                  <th>Influencer mail</th>
                  <th>Attendence</th>
                  <th>UTR_field_No.</th>
                  <th>RozarPay Order ID</th>           
                  <th>RozarPay Payment ID</th>
                  <th>Session Type</th>
                  <th>Influncer Fee</th>
                  <th>Platform fee</th>
                  <th>GST</th>
                  <th>RazorPay Charge</th>
                  <th>Total Amount</th>
                  <th>Date and Time</th>
                  <th>Status</th>
                  <th>Payment_Status</th>            
                  <th>Action</th>                
                </tr>
              </thead>
              <tbody>
                {requests.map((req, index) => (
                  <tr key={req._id}>
                    <td>
                     {/*{editIndex === index ? (
                        <input
                          type="text"
                          value={`${editedData[index].client.firstName} ${editedData[index].client.lastName}`}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            const [firstName, lastName] =
                              e.target.value.split(" ");
                            newEditedData[index].client.firstName = firstName;
                            newEditedData[index].client.lastName = lastName;
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        `${req.client.firstName} ${req.client.lastName}`
                      )} */}
                      {`${req.client.firstName} ${req.client.lastName}`}
                    </td>
                    <td>
                      {`${req.client.email} `}
                    </td>
                    <td>
                      {`${req.influencer.firstName} ${req.influencer.lastName}`}
                    </td>
                    <td>
                      {`${req.influencer.email} `}
                    </td>
                    <td>{editIndex === index ? (
                      <input
                          type="text"
                          value={editedData[index].attendence}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              attendence: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                    ) : (
                      `${req.attendence}`
                    )} </td>
                    <td>{editIndex === index ? (
                      <input
                          type="text"
                          value={editedData[index].utr_no}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              utr_no: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                    ) : (
                      `${req.utr_no}`
                    )} </td>
                    <td>
                     {/*} {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].razorpay_order_id}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              razorpay_order_id: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.razorpay_order_id
                      )} */}
                      {req.razorpay_order_id}
                    </td>
                    <td>
                     {/*} {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].razorpay_payment_id}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              razorpay_payment_id: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.razorpay_payment_id
                      )} */}
                      {req.razorpay_payment_id}
                    </td>
                    <td>
                      {`${req.slotid.meetingType} `}
                    </td>
                    <td>
                    ₹{req.amount - (req.platformfee * 0.18 + req.platformfee)}
                    </td>
                    <td>
                    ₹{req.platformfee}
                    </td>
                    <td>
                    ₹{req.platformfee * 0.18}
                    </td>   
                    <td>
                    ₹{Math.round((req.amount *2.36)/100*100)/100}
                    </td>                 
                    <td>
                     {/* {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].amount}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              amount: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.amount
                      )} */}
                      ₹{req.amount}
                    </td>
                    <td>
                     {/* {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].amount}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              amount: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.amount
                      )} */}
                      {new Date(req.selectedSlot.date).toLocaleDateString('en-GB')} <br />
                        {req.selectedSlot.startTime} - {req.selectedSlot.endTime}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <select
                          className="form-control"
                          value={editedData[index].status}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              status: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        >
                          {status.map((statusOption, optionIndex) => (
                            <option key={optionIndex} value={statusOption}>
                              {statusOption}
                            </option>
                          ))}
                        </select>
                      ) : (
                        req.status
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <select
                          className="form-control"
                          value={editedData[index].paymentStatus}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              paymentStatus: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        >
                          {paymentStatus.map((statusOption, optionIndex) => (
                            <option key={optionIndex} value={statusOption}>
                              {statusOption}
                            </option>
                          ))}
                        </select>
                      ) : (
                        req.paymentStatus
                      )}
                    </td>
                    
                    <td>
                      {editIndex === index ? (
                        <React.Fragment>
                          <button
                            className="btn btn-info"
                            onClick={() => handleSave(editedData[index]._id,editedData[index].status,editedData[index].paymentStatus,editedData[index].attendence,editedData[index].utr_no,index)}
                          >
                            Save
                          </button>
                        </React.Fragment>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEdit(index)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-right">
            <button
              className="btn btn-outline-primary mt-2 mb-2"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            {renderPageButtons()}
            <button
              className="btn btn-outline-primary mt-2 ml-2 mb-2 mr-2"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next Page
            </button>
          </div>
          </div>
          )}
        </div>
      </div>
      {alertData && (
        <div className="overlay">
          {/* You can add an overlay to dim the background if needed */}
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  );
};

export default AllRequests;
