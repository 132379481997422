import React from 'react'
import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import styles from './BookInfluencer.module.css'; 



const MakePayment = () => {
  return (
    <>
        <div className='text-center' style={{marginTop:"100px"}}>
          <div>
          <FontAwesomeIcon className='fs-160 sadcry'style={{
          fontSize: "82px", color: "rgb(224 101 128)"}} icon={faSmileBeam}/>
          <h1 className='text-muted text-info'>Your Session is Booked</h1>     
          <Link to="/clienthome" >
          <button className='btn btn-outline-danger'>Redirect to Dashboard</button>          
          </Link>
          </div>
          <img className={`mt-2 ${styles.imgBooked}`} src="/img/booked_session_pic.png" alt="#" />         
        </div>  
        <div className='ml-3'>
        <span className=' mt-5 text-danger' style={{fontSize:"70%"}}>**Sign in again after 48 hours to check the status of your booking.</span>  
        </div>  
    </>
  )
}

export default MakePayment