import React,{useState,useEffect} from 'react'
import { fetchInfluencerAccept, fetchInfluencerAfterTimeComplete, handleUpdateRequest } from '../../../services/auth-service/requestService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';


const CompleteSessions = ({initialValues}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [alertData, setAlertData] = React.useState(null);

    
    const influencerId = initialValues._id;
    const [requests,setRequests]=useState([])
    
    const InfluencerRequests = async(influencerId,page)=>{
            try {
              const response = await fetchInfluencerAfterTimeComplete(influencerId,page);
              if(!response.status === 200){
                setAlertData({
                  message: 'failed to fetch Requests',
                });
              }else if(response===204){
               setAlertData({
                message: 'No Session History',
              });
              }else{
              setRequests(response.markcompleteRequests);
              setTotalPages(response.totalPages);}
            } catch (error) {
              console.log(error);
       }
    }
    
    useEffect(() => {
        // Fetch data when the component mounts or when currentPage changes
        InfluencerRequests(influencerId, currentPage);
      }, [currentPage, influencerId]);
      const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };
    
      const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
      };
      const handlePageClick = (page) => {
        setCurrentPage(page);
      };
    
      const renderPageButtons = () => {
        const buttons = [];
      
        const renderEllipsis = () => (
          <span className='ml-2 mt-2'>...</span>
        );
      
        for (let i = 1; i <= totalPages; i++) {
          if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
            // Display first, last, and nearby pages
            buttons.push(
              <button
                className={`btn btn-outline-primary mt-2 ml-2 ${i === currentPage ? 'active' : ''}`}
                key={i}
                onClick={() => handlePageClick(i)}
                disabled={i === currentPage}
              >
                {i}
              </button>
            );
          } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
            // Display ellipsis if not already displayed
            buttons.push(renderEllipsis());
          }
        }
      
        return buttons;
      };

      const markCompleted = async (_id) => {
        try {    
          // Call handleUpdateRequest and accesToken
          const response = await Promise.all([handleUpdateRequest(_id, 'completed','Remittance pending')]);
    
          // Check if any of the responses is 204
          if (response.some((res) => res === 204)) {
            setAlertData({ message: 'No Session History' });
          }
    
          // Filter out the request with the given _id
          setRequests((prevRequests) => prevRequests.filter((request) => request._id !== _id));
          setCurrentPage(1);
        } catch (error) {
          console.error('Error marking request as accepted:', error);
          setAlertData({ message: 'Failed to mark request as accepted' });
        } 
      };

  return (
    <>
    <div className="inflanar-profile-info mg-top-30 inflanar-profile-info__list-container">
    <div className="inflanar-profile-info__head">
      <h3 className="inflanar-profile-info__heading">Session History</h3>
    </div>
    <ul className="inflanar-profile-info__list inflanar-dflex-column list-none">
  
      <li className="inflanar-dflex border border-primary-light rounded p-3">
        <div className='col-3'>
          <h4 className="inflanar-profile-info__title text-dark">Name</h4>
        </div>
        <div className='col-2'>
          <h4 className="inflanar-profile-info__title text-dark">Session Date</h4>
        </div>
        <div className='col-2 text-center'>
          <h4 className="inflanar-profile-info__title text-dark">Fee</h4>
        </div>
        <div className='col-2 '>
          <h4 className="inflanar-profile-info__title text-dark">Completion Status</h4>
        </div>
        <div className='col-3'>
          <h4 className="inflanar-profile-info__title text-dark">Action</h4>
          <div className="ml-auto"></div>
        </div>
      </li>
  
      {/* Dynamic Content Rows */}
      {requests.map((request) => (
        <li key={request._id} className="inflanar-dflex border border-primary-light p-3 rounded">
          <div className="col-3">
            <h4 className="inflanar-profile-info__title">{request.client.firstName} {request.client.lastName}</h4>
          </div>
          <div className="col-2">
            <h4 className="inflanar-profile-info__title">{new Date(request.selectedSlot.date).toLocaleDateString('en-GB')} <br />{request.selectedSlot.startTime}-{request.selectedSlot.endTime}</h4>
          </div>
          <div className="col-2 text-center">
            <h4 className="inflanar-profile-info__title">₹{request.amount - (request.platformfee * 0.18 + request.platformfee)}</h4>
          </div>
          <div className="col-2">
          <h4 className="inflanar-profile-info__title">
            {request.status === 'markcomplete' ? (
              <button className="btn btn-outline-success" disabled>Pending</button>
            ) : (
              <button className="btn btn-outline-info" disabled>Completed</button>
            )}
          </h4>
        </div>
          <div className="col-3">
              <button className="btn btn-outline-success" onClick={() => markCompleted(request._id)}>Mark Complete</button>
          </div>
        </li>
      ))}
    </ul>
    <div className='text-right'>
      <button className='btn btn-outline-primary mt-2' onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous Page
      </button>
      {renderPageButtons()}
      <button className='btn btn-outline-primary mt-2 ml-2' onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next Page
      </button>
    </div>
  </div>
  
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
  )
}

export default CompleteSessions