import HttpService from "../http-service/HttpService";

 //  /api/acesstoken
const accesToken = async (_id)=>{ 
  try{
    const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/acesstoken`,{_id:_id})
    if(response.status===200){  
      return response;}
      else if(response.status===204){
        return response.status;
       }
    } catch (error) {
      console.error('Error fetching Influencer requests:', error);
      if(error.response.status===204){
        return 204;
      }
    }
}

export {accesToken}