import React from 'react';

const VideoPlayer = () => {
    return (
      <div className="video-container">
        <div className="video-responsive">
        <iframe width="560"
         height="315" 
         src="https://www.youtube-nocookie.com/embed/YdAm5c8MVHg?si=hf8OxRm7Hb_bk_OP&rel=0" 
         title="YouTube video player" 
         frameBorder="0" 
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
         referrerPolicy="strict-origin-when-cross-origin" 
         allowFullScreen></iframe>
        </div>
      </div>
    );
  };

export default VideoPlayer;