import React, { useState, useEffect,useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import styles from './markfreeCalender.module.css';
import { getimeslot, markTimeSlot,bookedtimeslot } from '../../services/auth-service/timeSlot';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';


const MarkFreeSlots = ({ influencerId, onClose }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [meetingType, setMeetingType] = useState('Single');
  const [participants, setParticipants] = useState(1);
  const [alertData, setAlertData] = React.useState(null);
  const [lastFetchedDates, setLastFetchedDates] = useState([]);
  const isFirstRender = useRef(true);

// Fetch selected dates initially when influencerId changes
useEffect(() => {
  const fetchSelectedDates = async () => {
    try {
      const response = await bookedtimeslot(influencerId);
      const existingDates = response.data.map((slot) => new Date(slot.date));
      setSelectedDates(existingDates);
      setLastFetchedDates(existingDates); // Store the fetched dates to avoid infinite loops
    } catch (error) {
      console.error('Error fetching selected dates:', error);
    }
  };

  fetchSelectedDates();
}, [influencerId]);

// Re-fetch selected dates if influencerId or selectedDates change, but avoid infinite loop
useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return;
  }

  const fetchSelectedDates = async () => {
    try {
      const response = await bookedtimeslot(influencerId);
      const existingDates = response.data.map((slot) => new Date(slot.date));
      
      // Update selectedDates only if it is different from the last fetched data
      if (JSON.stringify(existingDates) !== JSON.stringify(lastFetchedDates)) {
        setSelectedDates(existingDates);
        setLastFetchedDates(existingDates); // Update the last fetched dates to prevent loops
      }
    } catch (error) {
      console.error('Error fetching selected dates:', error);
    }
  };

  fetchSelectedDates();
}, [influencerId, selectedDates, lastFetchedDates]);

  const handleMeetingTypeChange = (e) => {
    const selectedType = e.target.value;
    setMeetingType(selectedType);

    // Set participants to 5 when switching to 'multiple' meeting type
    if (selectedType === 'Group') {
      setParticipants(5);
    } else {
      // Reset participants to 1 for 'single' meeting type
      setParticipants(1);
    }
  };

  const handleParticipantsChange = (e) => {
    setParticipants(parseInt(e.target.value, 10));
  };
const handleDateClick = (date) => {
    const today = new Date();
    const threeDaysAhead = new Date(today.getTime() + (48 * 60 * 60 * 1000));

    if (date < threeDaysAhead) {
      return; // Do nothing if the date is less than 72 hours from now
    }

    if (!dateRange.start) {
      setDateRange({ start: date, end: null });
    } else {
      if (!dateRange.end) {
        setDateRange({ ...dateRange, end: date });
      } else {
        setDateRange({ start: date, end: null });
      }
    }
};

  const handleStartTimeChange = (e) => {
    const selectedStartTime = e.target.value;
    setStartTime(selectedStartTime);

    // Calculate end time (30 minutes later)
    const [startHour, startMinute] = selectedStartTime.split(':').map(Number);
    const endHour = startHour + Math.floor((startMinute + 30) / 60);
    const endMinute = (startMinute + 30) % 60;

    // Format end time in 'HH:mm' format
    const formattedEndTime = `${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}`;
    setEndTime(formattedEndTime);
  };

  const handleMarkFreeClick = async () => {
    if (!dateRange.start || !dateRange.end || !startTime || !endTime) {
      alert('Please select a valid date range and time slots.');
      return;
    }

    try {
      const istStartDate = moment(dateRange.start).tz('Asia/Kolkata').toDate();
      const istEndDate = moment(dateRange.end).tz('Asia/Kolkata').toDate();
      const dates = generateDateRange(istStartDate, istEndDate);
      const status =await markTimeSlot(influencerId, dates, startTime, endTime, meetingType, participants);
      if(status===207){
        setAlertData({
          message: 'Please select a time slot that is at least 72 hours from now.',
        });
      }
      else if(status===208){
        setAlertData({
          message: 'The slot of selected time already exists on this date',
        });
      }else{
      const newSelectedDates = [...selectedDates, ...generateDateRange(istStartDate, istEndDate)];
      setDateRange({ start: null, end: null });
      setStartTime('');
      setEndTime('');
      setSelectedDates(newSelectedDates);
      }
    } catch (error) {
      console.error('Error marking dates as free:', error);
    }
  };

  const generateDateRange = (start, end) => {
    const dates = [];
    let currentDate = moment(start);
  
    while (currentDate <= end) {   
      dates.push({
        date: currentDate.toISOString(),
        startTime: startTime,
        endTime: endTime,
      });
      currentDate.add(1, 'day');
    }
    return dates;
  };

  const isDateBeforeToday = (date) => {
    const today = new Date();
    const threeDaysAhead = new Date(today.getTime() + (48 * 60 * 60 * 1000)); // Set date to 72 hours (3 days) ahead
    return date < threeDaysAhead;
  };
  

  const isDateBooked = (date) => {
    const formattedDate = new Date(date).toDateString();
    return selectedDates.some((selectedDate) => new Date(selectedDate).toDateString() === formattedDate);
  };

  return (
    <>
    <div className="container mt-5 " style={{overflowY: "auto",maxHeight: "80%" }}>
      <div className="card">
        <span className={`btn btn-outline-dark close-btn ${styles.closebutton} mb-3`} onClick={onClose}>&times;</span>
        <div className="card-header">
          <h2 className="mb-0">Mark your calendar for available slots</h2>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-8 card p-4 r-4">
              <Calendar
                onClickDay={handleDateClick}
                tileClassName={({ date }) => {
                  let className = '';
                  if (isDateBeforeToday(date)) {
                    className = `${styles.beforetoday}`;
                  } else if (isDateBooked(date)) {
                    className = `${styles.bookeddate}`;
                  } else {
                    className = `${styles.selecteddate}`;
                  }
                  return className;
                }}
              />
              <div className='row mt-4 w-100'>
              <div className='col-md-4'>
                <label>Session Type:</label>
                <select
                  className="form-control mb-3"
                  value={meetingType}
                  onChange={handleMeetingTypeChange}
                >
                  <option value="Single">Single (1:1)</option>
                  <option value="Group">Group </option>
                </select>
              </div>
              {meetingType === 'Group' && (
                <div className='col-md-4'>
                  <label>Session Participants:</label>
                  <select
                    className="form-control mb-3"
                    value={participants}
                    onChange={handleParticipantsChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>
                </div>
              )}
            </div>
              <p className='mt-2'>To select a slot for the same day, double-click on the day. To select the same slot for
                consecutive days, first click on the start date and then click on the end date.</p>
              <p className='mt-2 text-danger'>Please contact admin at{' '}
                <a className='text-primary' href={`mailto:support@inkorero.com`}>support@inkorero.com</a> for any support.</p>
            </div>
            {dateRange.start && dateRange.end && (
              <div className="col-md-4">
                <label>Start Time:</label>
                <input
                  type="time"
                  className="form-control mb-3"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
                <label>End Time:</label>
                <input
                  type="time"
                  className="form-control mb-3"
                  value={endTime}
                  readOnly
                />
                <button className="btn btn-primary" onClick={handleMarkFreeClick}>
                  Mark Free
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    {alertData && (
      <div className="overlay">
        {/* You can add an overlay to dim the background if needed */}
        <AlertMessage
          message={alertData.message}
          type={alertData.type}
          onClose={() => setAlertData(null)}
        />
      </div>
    )}
    </>
  );
};

export default MarkFreeSlots;
