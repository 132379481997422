import React,{useState,useEffect} from 'react'

import InfluencerSignup from './InfluencerSignup';
import ClientSignup from './ClientSignup';



const SignUp=()=>{
  //const history = useHistory()
  const [toggler,settoggler]=useState('')
  const [isActiveButton1, setIsActiveButton1] = useState(true);
  const [isActiveButton2, setIsActiveButton2] = useState(false);
 

const [isActive1, setIsActive1] = useState(true);
const [isActive2, setIsActive2] = useState(false);


  const toggleClassButton1 = () => {
    settoggler('influencer')
    setIsActiveButton1((prevIsActive) => !prevIsActive);
    setIsActiveButton2(false); // Ensure the other button is inactive
    //console.log(toggler);
    setIsActive1(true);
    setIsActive2(false);
  };

  const toggleClassButton2 = () => {
    settoggler('client')
    setIsActiveButton2((prevIsActive) => !prevIsActive);
    setIsActiveButton1(false); // Ensure the other button is inactive
    //intab1
    //console.log(toggler);
    setIsActive1(false);
    setIsActive2(true);

  };
  useEffect(() => {
    
  })
  
  const storedata =async (data,)=>{
    
  }
// eslint-disable-next-line
    return(<>
<section className="inflanar-signin pd-top-120 pd-btm-120">
<div className="container">
<div className="row">
<div className="col-12">
<div className="inflanar-signin__body">
<div className="row">
<div className="col-lg-6 col-12">
<div className="inflanar-signin__logins">

<div className="inflanar-signin__header mg-btm-10">
<div className="inflanar-signin__heading">
<h4 className="inflanar-signin__title">Sign Up</h4>
<p className="inflanar-signin__tag">Welcome to In Korero</p>
</div>
<div className="inflanar-signin__heading__options">

<div className="list-group inflanar-signin__options" id="list-tab" role="tablist">
<button className={`list-group-item ${isActiveButton1 ? 'active' : 'inactive'}`} data-bs-toggle="list" 
name="client" value="client" role="tab" onClick={toggleClassButton1}>
<span>Influencer</span>
</button>
<button className={`list-group-item ${isActiveButton2 ? 'active' : 'inactive'}`} style={{borderTopWidth:'1px'}} 
data-bs-toggle="list" name="Inluencer" value="Influencer"  
role="tab" onClick={toggleClassButton2}>
<span>User</span>
</button>
</div>
</div>
</div>

<div className="tab-content" id="nav-tabContent">

<div className={`tab-pane ${isActive1 ? 'active' : ''}`} id="intab1" role="tabpanel" >
{ /*  Influencer SingUp */}
<InfluencerSignup myfun={storedata} />
</div>


<div className={`tab-pane ${isActive2 ? 'active' : ''}`} id="intab2" role="tabpanel">
{ /*  Client SingUp */}
<ClientSignup />
</div>

</div>
</div>
</div>
<div className="col-lg-6 col-12 d-none d-lg-block">
<div className="inflanar-signin__welcome inflanar-bg-cover inflanar-section-shape18">
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
      </>
    )
}
export default SignUp