import React, { useEffect, useState } from "react";
import styles from "../pages/influencerAbout/influencerComponents/influenceredit.module.css";
import Editprofile from "./Profile";
import RequestSessionDetails from "./RequestSessionDetails";

const UserDashBoard = ({ values, currentPage, setCurrentPage }) => {
  const [users, setUsers] = useState(values);
  const [editedInfluencer, setEditedInfluencer] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showPopupForm, setShowPopupForm] = useState(false);
  const [userId, setUserId] = useState();
  const [usersPerPage] = useState(5);

  useEffect(() => {
    setUsers(values);
  }, [values]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(users.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleToggleOverlay = (influencer) => {
    setShowOverlay(!showOverlay);
    setEditedInfluencer(influencer);
  };

  const handleOpenPopupForm = (id) => {
    setShowPopupForm(true);
    setUserId(id);
  };

  const handleClosePopupForm = () => {
    setShowPopupForm(false);
  };

  return (
    <>
      <div className="card" style={{ overflowY: "auto", maxHeight: "100%" }}>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Email</th>
                <th>Category</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={index}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.category}</td>
                  <td>
                    <button
                      className={`btn btn-outline-info ${styles.buttonsize}`}
                      onClick={() => handleToggleOverlay(user)}
                    >
                      Profile
                    </button>
                    <button
                      className={`btn btn-outline-primary ml-2 ${styles.buttonsize}`}
                      onClick={() => handleOpenPopupForm(user._id)}
                    >
                      Sessions
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav>
          <div className="text-muted" style={{ float: "left", marginRight: "5px" }}>
            Page {currentPage} of {totalPages}
          </div>
          <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index} className={`page-item ml-2 ${currentPage === index + 1 ? 'active' : ''}`}>
                <button onClick={() => paginate(index + 1)} className="page-link">
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ml-2 ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link mr-2" onClick={() => paginate(currentPage + 1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {showOverlay && (
        <div className="overlay">
          <Editprofile
            initialValues={editedInfluencer}
            onClose={handleToggleOverlay}
          />
        </div>
      )}

      {showPopupForm && (
        <div className="overlay mw-100">
          <RequestSessionDetails
            initialValues={userId}
            onClose={handleClosePopupForm}
          />
        </div>
      )}
    </>
  );
};

export default UserDashBoard;
