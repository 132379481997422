import React from 'react';
import styles from './Aboutus.module.css'; // Import CSS module
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

const AboutUs = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.teamSection}>
        <div className={styles.teamMember}>
          <img src="img/Ashwini.jpg" alt="Ashwini Kumar" className={styles.profileImage} />
          <h3 className={styles.name}>Ashwini Kumar</h3>
          <p className={styles.role}>Co-founder</p>
          <span className={styles.linkedinIcon}>
          <FontAwesomeIcon
          className={`${styles.faLinkedinIcon} ml-2 ${styles.icons} rounded-circle`}
          icon={faLinkedinIn}
          onClick={() => window.open('https://www.linkedin.com/in/ashwini-kumar-701310b4/', '_blank')}
        />
          </span>
        </div>

        <div className={styles.teamMember}>
          <img src="img/Abhijit (2).JPG" alt="Abhijit Bharadwaj" className={styles.profileImage} />
          <h3 className={styles.name}>Abhijit Bharadwaj</h3>
          <p className={styles.role}>Co-founder</p>
          <span className={styles.linkedinIcon}>
          <FontAwesomeIcon
          className={`${styles.faLinkedinIcon} ml-2 ${styles.icons} rounded-circle`}
          icon={faLinkedinIn}
          onClick={() => window.open('https://www.linkedin.com/in/abhijit-bharadwaj-1874682/', '_blank')}
        />
          </span>
        </div>

        <div className={styles.teamMember}>
          <img src="img/Supriya.jpg" alt="Supriya Bharadwaj" className={styles.profileImage} />
          <h3 className={styles.name}>Supriya Bharadwaj</h3>
          <p className={styles.role}>Co-founder</p>
          <span className={styles.linkedinIcon}>
          <FontAwesomeIcon
          className={`${styles.faLinkedinIcon} ml-2 ${styles.icons} rounded-circle`}
          icon={faLinkedinIn}
          onClick={() => window.open('https://www.linkedin.com/in/supriya-bharadwaj-1694682/', '_blank')}
        />
          </span>
        </div>

        <div className={styles.teamMember}>
          <img src="img/Ankush Ahuja.jpg" alt="Ankush Ahuja" className={styles.profileImage} />
          <h3 className={styles.name}>Ankush Ahuja</h3>
          <p className={styles.role}>Advisor</p>
          <span href="instagram_link" className={styles.instagramIcon}>
          <FontAwesomeIcon
          className={`${styles.instaIcon} ml-2 ${styles.icons} rounded-circle`}
          icon={faInstagram}
          onClick={() => window.open('https://www.instagram.com/kimianks/', '_blank')}
        />
          </span>
        </div>

        <div className={styles.teamMember}>
          <img src="img/Vikas.jpg" alt="Vikas Sharma" className={styles.profileImage} />
          <h3 className={styles.name}>Vikas Sharma</h3>
          <p className={styles.role}>Advisor</p>
          <span href="linkedin_link" className={styles.linkedinIcon}>
          <FontAwesomeIcon
          className={`${styles.faLinkedinIcon} ml-2 ${styles.icons} rounded-circle`}
          icon={faLinkedinIn}
          onClick={() => window.open('https://www.linkedin.com/in/vikas-sharma-139b877/', '_blank')}
        />
          </span>
        </div>
      </div>

      <div className={styles.aboutSection}>
        <h2>About Us</h2>
        <p>
          "Kōrero" is a word from the Māori language, meaning "a conversation." IN KORERO is designed to provide innovative and convenient solutions that empower influencers, professionals, and individuals alike. Our platform enables influencers and professionals to maintain meaningful connections with their audiences, addressing the growing need for enhanced communication and knowledge sharing.
        </p>
        <p>
          IN KORERO gives influencers and professionals the opportunity to monetize their spare time, while audiences can fulfill their aspiration of interacting with influencers and professionals in a seamless manner. We are committed to providing a streamlined solution that optimizes engagement, enhances operational efficiency, and ensures sustained growth for all users.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
