import React from 'react'

const CancellationPage = () => {
  return (
    <section className="inflanar-bg-cover pd-top-40 pd-btm-40" >
    <div className="container">
    <div className="row">
    <div className="col-12">
    
    
    <div className="inflanar-page-content mg-top-40 ">
    <h4>Cancellation and Refund</h4>
    <p>
    Once You have booked a Session it cannot be cancelled or rescheduled, the amount cannot be refunded or redeemed against any other benefit.
    </p>
    <p>There will not be any refund in case of no show by the User. User can’t reschedule/cancel the Session irrespective of Meeting Request acceptance status by the Influencer. User is entitled to get the refund in case it is found by IN KORERO that the Session has not been conducted by the Influencer or Meeting Request has been declined by the Influencer or Meeting Request has been auto declined. In such case 
    User will get the refund of the Influencer fee in 15 business days in the same mode of payment. The User shall get a refund of the fee paid after deducting the platform fee as well as the GST and taxes on the platform fee, however the Influencer fee shall not be deducted. It is hereby clarified that IN KORERO platform fee and GST and Taxes for the platform fee are non-refundable in any circumstances.
     In case of no show by the Influencer, the User must contact IN KORERO within 48 hours from the Session completion time to submit refund claim for verification; after 48 hours, no such claims will be entertained. In all cases discretion of refund vests with IN KORERO.</p>
    <p>Once the Participants have joined the Session, IN KORERO considers the Session to have taken place and waits for the Influencer to mark it as completed, irrespective of the Session's duration. IN KORERO is not liable for any refund or partial refund if any of the Participants leave the Session early.</p>
    <p>Please refer to our detailed Terms and Conditions at <a className='text-primary' href='https://inkorero.com/terms-condition'>https://inkorero.com/terms-condition</a></p>
    <br/>
    </div>
    
    
    
    </div>
    </div>
    </div>
    </section>
  )
}

export default CancellationPage