import React,{useState} from 'react'
import styles from './ContactUs.module.css'
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { confirmMail } from '../../services/auth-service/requestService';


const ContactUs=()=>{
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        message: '',
      };
    const [ContactData, setContactData] = useState(initialFormData);
    const [alertData, setAlertData] = React.useState(null);
    const isValidPhone = (phone) => {
      // Regular expression for basic 10-digit phone number format validation
      const phoneRegex = /^\d{10}$/;  
      if (!phoneRegex.test(phone)) {
        // Phone number format is not valid, show alert
        alert('Phone number must be a 10-digit number');
        return false;
      }
      // Phone number format is valid
      return true;
    };
    const isValidEmail = (email) => {
      // Regular expression for basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        // Email format is not valid, show alert
        setAlertData({
          message: 'Please email properly',
        });
        return false;
      }
    
      // Email format is valid
      return true;
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setContactData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
    const handleContact =async (e)=>{
        e.preventDefault(); 
        if(ContactData.name && isValidEmail(ContactData.email) && isValidPhone(ContactData.phone) && ContactData.message){
        setAlertData({
            message: 'Thank you for reaching out to us! Your message is important to us and will respond to you as soon as possible.',
          });
          const subject = `Query from ${ContactData.name} (${ContactData.email})`
          const message = `<p>${ContactData.message} 
          </p>
          <p>
          ${ContactData.name}</p><p>
          ${ContactData.phone}</p>`
          const email = `support@inkorero.com`;
          await confirmMail({ subject,message,email});
          resetFormData();
        }else{
            setAlertData({
                message: 'Please fill All the fields',
              });
        }
    }
    const resetFormData = () => {
        setContactData(initialFormData);
      };

return(
    <>
    <div className='dm'>
    <div className='row containerColor mt-0'>
      <div className={`col-lg-5 ${styles.mt_10} p-5 `}>
        <h1 className={`txt ${styles.fs_20}`}>
          Be Motivated<br></br>
          and Contact us
        </h1>
      </div>
      <form className='col-lg-5 mt-5 dm p-4 ' onSubmit={handleContact}>
        <p>
         
          support@inkorero.com
        </p>
        <div className="input-icons mb-4 mg-top-30">
          <input
            type="text"
            className="mb-4"
            name="name"
            id={styles.inp}
            placeholder="Name"
            value={ContactData.name}
            onChange={handleInputChange}
          />
          <input
            type="email"
            className="mb-4"
            name="email"
            placeholder="Email"
            id={styles.inp}
            autoComplete="true"
            value={ContactData.email}
            onChange={handleInputChange}
          />
          <input
            type="number"
            className="mb-4"
            name="phone"
            placeholder="Phone"
            id={styles.inp}
            maxLength={10}
            value={ContactData.phone}
            onChange={handleInputChange}
          />
          <textarea
            type="text"
            className="mb-4"
            name="message"
            id={styles.inp}
            placeholder="Message up to 50 words"
            maxLength={400}
            value={ContactData.message}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <button type="submit" className="btn btn-outline-dark mb-4">Submit</button>
        </div>
      </form>
    </div>
  </div>
{alertData && (
    <div className="overlay">
      {/* You can add an overlay to dim the background if needed */}
      <AlertMessage
        message={alertData.message}
        type={alertData.type}
        onClose={() => setAlertData(null)}
      />
    </div>
  )}
</>
)}
export default ContactUs