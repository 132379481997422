import React,{useContext} from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './Navbar.module.css'
import { Link } from "react-router-dom";
import { UserContext } from '../../../App';

const Navbar = () =>  {
  const {state,dispatch} = useContext(UserContext);
  const RenderMenu = ()=>{
    if(state){
      return(
        <>
          <li className="nav-item margin-right-20">
          <Link to="/logout" className="inflanar-btn inflanar-btn--header">
            Logout
          </Link>
          </li>
        </>
      )
    }else{
      return(
        <>
        <li className="nav-item margin-right-20">
        <Link to="/signin" className="p-button p-button-nbg pr-4">
          Sign In
        </Link>
      </li>
      <li className="nav-item margin-right-20">
      <Link to="/auth/signup" className="inflanar-btn inflanar-btn--header">
        Sign Up
      </Link>
      </li>
        </>
    )}
  }
    return (
      <div className="dm">
        <nav className={`navbar navbar-expand-lg navbar-primary ${styles.navbar_main_header}`}>
          <Link to="/" className="navbar-brand" style={{padding:"0"}}>
            <img
              src="/img/logo.png"
              alt="LogoAppear"
              className={styles.navbar_logo}
            />
          </Link>
          <span
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} />
          </span>
         <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link to="/" className="p-button p-button-nbg" href="#">
                
              </Link>
            </li>
          </ul>
          <div className={`navbar-text`}>
            <ul className="navbar-nav" >
            <RenderMenu />
            </ul>
          </div>
    </div> 
        </nav>
      </div>
    );
  }


export default Navbar;
