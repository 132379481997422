import React, { useState, useContext } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LoginInfluen, forgotInfluencer } from "../../services/auth-service/InflAccountService";
import { UserContext } from '../../App';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { forgotClient } from '../../services/auth-service/clientAccountService';
import { subjectEmail, emailMessage,otpSubject,otpMessage} from '../../utils/common'

const Forgotpassword = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);
  const [alertData, setAlertData] = useState(null);


  const navigate = useNavigate();
  const [inputdata, setinputdata] = useState({
    email: '',
  })
  const toggler = location.state && location.state.toggler;

  const handleinput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setinputdata({
      ...inputdata, [name]: value,
      subject: otpSubject,
      message: otpMessage
    })
  }

  const postData = async (e) => {
    let settoggler = 'toggler';
    const data = {
      ...inputdata,
      [settoggler]: toggler
    }
    e.preventDefault();
    let res;
    if (toggler === 'influencer') {
      res = await forgotInfluencer(inputdata)
    } else if (toggler === 'client') {
      res = await forgotClient(inputdata)
    }
    if (res === 400 || !res) {
      setAlertData({
        message: 'Invalid User!!',
      });
    } else {
      setinputdata([])
      navigate('/otpverify', { state: { data: data } })
    }
  }
  return (
    <>
      <section className="inflanar-signin pd-top-120 pd-btm-120">
        <div className="container" style={{ maxWidth: "720px" }}>
          <div className="row">
            <div className="col-12">
              <div className="inflanar-signin__body">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="inflanar-signin__logins">

                      <div className="inflanar-signin__header mg-btm-10">
                        <div className="inflanar-signin__heading">
                          <h4 className="inflanar-signin__title">Forgot Password</h4>
                        </div>

                      </div>

                      <div className="tab-content mg-top-30" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="in-tab10" role="tabpanel">
                          <div className="inflanar-signin__inner">
                            <form method='POST'>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group inflanar-form-input mg-top-20">
                                    <label>Email*</label>
                                    <input className="ecom-wc__form-input" type="email" name="email"
                                      placeholder="infoyour@gmail.com" required="required" value={inputdata.email}
                                      onChange={handleinput} />
                                  </div>
                                  <div className="form-group mg-top-40">
                                    <button type="submit" className="inflanar-btn" onClick={postData}><span>Submit</span></button>
                                  </div>
                                  <div className="inflanar-signin__bottom">
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {alertData && (
        <div className="overlay">
          {/* You can add an overlay to dim the background if needed */}
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  )
}

export default Forgotpassword