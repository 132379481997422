
const subjectEmail=`Your One-Time Password (OTP) for Account Verification`
const emailMessage = `Your OTP for account verification is: PLACEHOLDER_OTP

Please use this OTP within the next 2 minutes to complete the verification process.
For influencers, please also verify your social media account to activate your account.

Thank you for your cooperation.
Best regards, In Korero Team
  `;

const subjectsocialEmail = `Your One-Time Password (OTP) for Social Media Account Verification`
const socialEmailMessage = `Your OTP for account verification is: PLACEHOLDER_OTP.

Verifying social media accounts helps prevent imposters from using others' accounts. Only influencers with at least one verified social media account can conduct sessions.
For the verification process, In Korero seeks confirmation that you have access to the social media account listed in your In Korero profile. To ensure this, please perform the following steps and use this OTP within the next 10 minutes.

Steps for Social Media Account Verification:
1. Login to your social media account: Instagram, Facebook, X (Twitter), or LinkedIn.
2. Find the In Korero account: Use the following links to locate the official In Korero profiles on each platform:
   - Instagram: https://www.instagram.com/inkorero/
   - Facebook: https://www.facebook.com/people/In-Korero/61556260465127/
   - X (Twitter): https://twitter.com/InKorero
   - LinkedIn: https://www.linkedin.com/company/inkorero/
3. Send your OTP :
   - Direct message (DM) the ‘OTP’ to the respective In Korero account.

After you share the ‘OTP’ with us, it may take up to 5 working days to verify your social media account. Please do not start the verification process again to avoid confusion. Once verified, the verification button will be disabled, and clients will see a verified tick on your profile. We appreciate your patience and will contact you if there are any issues.

Thank you for your cooperation.
Best regards, In Korero Team`

const otpSubject =`Your One-Time Password (OTP) for Password Reset`
const otpMessage = `Your OTP for password reset is: PLACEHOLDER_OTP.

Please use this OTP within the next 2 minutes to complete the verification process.

Thank you for your cooperation.
Best regards, In Korero Team
`



const mailbody = ({razorpay_order_id,selectedSlot,amount},amountforhalf,gstRate,platformAmount,RazorpayCharge)=>{
const subject = `In Korero order ID: ${razorpay_order_id}`
// Embedding CSS styles into the HTML content
// to be check the date in US format
const message = `
<div style="font-family: Arial, sans-serif;">
  <div>
    Order ID: ${razorpay_order_id}<br>
    Booked Session: ${new Date(selectedSlot.date).toLocaleDateString()}, ${selectedSlot.startTime} - ${selectedSlot.endTime}<br>
    <div className="container mt-5" style="max-width: 40% !important;">
      <h3 style="margin-left: 10px; margin-top: 20px;">Order Summary</h3>
      <table className="table" style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
        <tbody>
          <tr style="border-bottom: 1px solid #ddd;">
            <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>
            Influencer Fee</strong><span style={{fontSize:"80%"}}>(including GST and Taxes)</span></td>
            <td style="padding: 8px;">₹${amountforhalf}</td> 
          </tr>
          <tr style="border-bottom: 1px solid #ddd;">
                <td style="padding: 8px; border-right: 1px solid #ddd;"><p><strong>Platform Fee</strong><span style={{fontSize:"80%"}}>(including 2.36% payment gateway charges)</span> </p></td>
                <td style="padding: 8px;"><p>₹${platformAmount}</p></td> 
              </tr>
          <tr style="border-bottom: 1px solid #ddd;">
            <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>GST on Platform</strong> <span style="font-size: 80%;">(9%CGST and 9%CGST)</span></td>
            <td style="padding: 8px;">₹${gstRate}</td> 
          </tr>
          <tr>
            <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>Total Amount Payable </strong><span style="font-size: 80%;">(inclusive of all taxes)</span></td>
            <td style="padding: 8px;">₹${amount}</td> 
          </tr>
        </tbody>
      </table>
    </div>
    <span style={{fontSize:"80%",color:"grey"}}>Payment gateway charges 2.36% amounts to ₹${RazorpayCharge} </span>        
    <p>
    Please allow 48 hours for the influencer to confirm your booking and kindly sign in again after 48 hours to check your booking status. Booked sessions cannot be cancelled or rescheduled. 
    </p>
    <p>
    If the session is declined or, in the unlikely event that the influencer becomes unavailable after accepting the session request, rest assured that a refund will be promptly initiated. We will process a refund of the amount paid, after deducting the platform fee and applicable taxes, within 15 business days.Please note that the platform fee and associated GST are non-refundable.
    </p>
    <p>
    Please refer to our
    detailed Terms and Conditions at <a href="https://inkorero.com/terms-condition" style="color: #007bff; text-decoration: none;">https://inkorero.com/terms-condition</a>.
    </p>
    
    <p>
    Thank you for your cooperation.
    </p>
    <p>
    Best regards, In Korero Team
    </p>
  </div>
</div>`;
return {subject,message};
}
const mailbodyForInf = ({razorpay_order_id,selectedSlot,amount},amountforhalf,gstRate,platformAmount,RazorpayCharge)=>{
  const subject = `In Korero order ID: ${razorpay_order_id}`
  // Embedding CSS styles into the HTML content
  const message = `
  <div style="font-family: Arial, sans-serif;">
    <div>
      Order ID: ${razorpay_order_id}<br>
      Booked Session: ${new Date(selectedSlot.date).toLocaleDateString()}, ${selectedSlot.startTime} - ${selectedSlot.endTime}<br>
      <div className="container mt-5" style="max-width: 40% !important;">
        <h3 style="margin-left: 10px; margin-top: 20px;">Order Summary</h3>
        <table className="table" style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
          <tbody>
            <tr style="border-bottom: 1px solid #ddd;">
              <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>
                Influencer Fee</strong><span style={{fontSize:"80%"}}>(including GST and Taxes)</span> </td>
              <td style="padding: 8px;">₹${amountforhalf}</td> 
            </tr>
            <tr style="border-bottom: 1px solid #ddd;">
                  <td style="padding: 8px; border-right: 1px solid #ddd;"><p><strong>Platform Fee</strong><span style={{fontSize:"80%"}}>(including 2.36% payment gateway charges)</span></p></td>
                  <td style="padding: 8px;"><p>₹${platformAmount}</p></td> 
                </tr>
            <tr style="border-bottom: 1px solid #ddd;">
              <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>GST on Platform</strong> <span style="font-size: 80%;">(9%CGST and 9%CGST)</span></td>
              <td style="padding: 8px;">₹${gstRate}</td> 
            </tr>
            <tr>
              <td style="padding: 8px; border-right: 1px solid #ddd;"><strong>Total Amount Payable </strong><span style="font-size: 80%;">(inclusive of all taxes)</span></td>
              <td style="padding: 8px;">₹${amount}</td> 
            </tr>
          </tbody>
        </table>
      </div>
      <span style={{fontSize:"80%",color:"grey"}}>Payment gateway charges amounts to ₹${RazorpayCharge} </span>        
      <p>
      People are looking forward to meeting you. Kindly sign in at <a href="https://inkorero.com" style="color: #007bff; text-decoration: none;">https://inkorero.com</a> to confirm your availability for the session. In case no action is taken to accept or decline the session within 48 hours from your end, the session will be automatically declined. Accepted sessions can’t be cancelled or rescheduled.
      
      Upon completion of the session, we'll promptly credit the influencer fee to your designated bank account within 15 business days. We'll be in touch to gather the necessary account details for the fee transfer.
      </p>
      <p>
      Please refer to our detailed Terms and Conditions at <a href="https://inkorero.com/terms-condition" style="color: #007bff; text-decoration: none;">https://inkorero.com/terms-condition</a>.
      </p>
      <p>
      Thank you for your cooperation.
      </p>
      <p>
      Best regards, In Korero Team
      </p>
    </div>
  </div>`;  
  return {subject,message};
  }

  const mailbodyAcceptance = ({razorpay_order_id})=>{
    const subject = `Order Summary for Order ID: ${razorpay_order_id}`
    // Embedding CSS styles into the HTML content
    const message = `<div>
<p><strong>Information:</strong></p>
<p>1) Please do not bring unauthorized individuals to the session.The influencer has the right to end or leave the session if they see unauthorized individuals.Refrain from forwarding this meeting invite to others, as it may result in unauthorized individuals joining the session.
</p>
<p>
2) Please exhibit your best behavior during the session.Avoid inappropriate gestures, offensive content, abusive language, and other disruptive behaviors.If you notice any such behavior, you may leave at your discretion and report it to In Korero.
</p>
<p>
3) Please mark the session as completed on the In Korero platform after the session so that In Korero can update their records. (Applicable for the Influencer.)
</p>
<p>
4) This session cannot be rescheduled or cancelled.This is a system-generated invite when the influencer accepts the session; therefore, the response options in the invite are not monitored.</p>
<p>
Please refer to our detailed Terms and Conditions at https://inkorero.com/terms-condition.
</p>
Thank you for your cooperation.
<p>
Best regards, In Korero Team
</p>
</div>`;
    
    
    return {subject,message};
  }
  const mailbodyDecline = ({razorpay_order_id})=>{
      const subject = `Session Declined for Order ID: ${razorpay_order_id}`
      // Embedding CSS styles into the HTML content
      const message = `<div>
  <p>We sincerely apologize for the inconvenience, as the influencer is unavailable for this session due to circumstances beyond our control. 
  We will process a refund of the amount paid, after deducting the platform fee and 
  GST on platform fee, within 15 business days in the same mode of payment.
  </p>
  <p>
  Please refer to our detailed Terms and Conditions at https://inkorero.com/terms-condition.
  </p>
  Thank you for your cooperation.
  <p>
  Best regards, In Korero Team
  </p>
  </div>`;
      
      
      return {subject,message};
  }

export {emailMessage,socialEmailMessage,subjectEmail,subjectsocialEmail,otpSubject,otpMessage,
  mailbody,mailbodyForInf,mailbodyAcceptance,mailbodyDecline}