import { decrypt } from "../common/decrpyt";
import HttpService from "../http-service/HttpService";



//To register Influencer
async function RegisterUser(registerdata){
    try {

        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/register`,registerdata)
        return data;
    } catch (error) {
        if(error.response.status===409){
            return 409;
        }
         console.log(error);
    }
}

//To Login Influencer
async function LoginInfluen(loginInfo){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/influencer/signin`,loginInfo, { withCredentials: true })
        return data;

    } catch (error) {
        if(error.response.status===400){
            return 400;
        }
        console.log(error);
    }

}


//To Get Influencer Info 
async function GetInfl_Info(){
    try {
        const response =await HttpService.get(`${process.env.REACT_APP_API_URL}/api/influencer/home`,{
            method:"GET",
            headers:{
              Accept:"application/json",
              "Content-Type":"application/json"
            },
            credentials:"include"
          });
          return response;
    } catch (error) {
        if(error.response.status===401){
            return 401;
          }
          throw error;
        //console.log(error);
    }
}

//To Update Influncer profile
const updateInfluencer = async(formData)=>{
try {
    const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/updateInfluencer`, formData);
    if(response.status===200){  
      return response;}
      else if(response.status===204){
        return response.status;
       }
    
  } catch (error) {
    if(error.response.status===204){
        return 204;
    }else if(error.response.status===400){
        return 400;
    }
    console.error('Error during user update:', error);
  }
}

//To Get Influencer Info 
async function logoutInfl(){
    try {
        const response =await HttpService.get(`${process.env.REACT_APP_API_URL}/api/logout`,{
            method:"GET",
            headers:{
              Accept:"application/json",
              "Content-Type":"application/json"
            },
            credentials:"include"
          });
          return response;
    } catch (error) {
        if(error.response.status===401){
            return 401;
          }
        console.log(error);
    }
}

//to get influencer verified 
const fetchInfluencers = async (page) => {
    try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/allinfluencers?page=${page}`);
    const encryptedData = response.data.data;
    const decryptedResponse = decrypt(encryptedData);
    return JSON.parse(decryptedResponse);

    } catch (error) {
      console.error('Error fetching influencers:', error);
      // Handle the error
    }
  };

//Forgot Influencer
async function forgotInfluencer(forgotmail,social){
  try {
    if(social){ forgotmail={...forgotmail,social};
  }  
  const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`,{forgotmail})
   
      return data;
  } catch (error) {
      if(error.response.status===400){
          return 400;
      }
      console.log(error);
  }

}

//OTP Influencer
async function OTPInfluencer(forgotOtp){
  try {
      const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/otp-verify`,forgotOtp)
      return data;

  } catch (error) {
      if(error.response.status===400){
          return 400;
      }
      console.log(error);
  }
}

//change Password via otp
async function PaswwordViaOTPInfluencer(forgotOtp){
  try {
      const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/reset-password`,forgotOtp)
      return data;

  } catch (error) {
      if(error.response.status===400){
          return 400;
      }
      console.log(error);
  }

}

// serach query
//to get all influencer 
const searchInfluencers = async (searchQuery,page) => {
  try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/influencersdebounce?search=${searchQuery}&page=${page}`);  //   setInfluencers(response.data.influencers);
      const encryptedData = response.data.data;
      const decryptedResponse = decrypt(encryptedData);
      return JSON.parse(decryptedResponse);
      } catch (error) {
        console.error('Error fetching influencers:', error);
        // Handle the error
      }
}


//Email Verify Influencer
async function VerifyEmail(email){
  try {
    const response = await HttpService.put(`${process.env.REACT_APP_API_URL}/api/update-verify-email`,{ email });
    if(response.status===200){  
      return response;}
      else if(response.status===204){
        return response.status;
       }

  } catch (error) {
      if(error.response.status===204){
          return 204;
      }
      console.log(error);
  }
}

//fetch All Influencer
const fetchAllInfluencers = async (page) => {
  try {
    const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/getallinfluencers`);
    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error fetching influencers:', error);
    throw error; // Throw the error to handle it in the calling function
  }
};


//search all influencer with debounce
const AllSearchInfluencersDebounce = async (searchQuery,page) => {
  try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/searchinfluencersdebounce?search=${searchQuery}&page=${page}`);  //   setInfluencers(response.data.influencers);
      return response;
      } catch (error) {
        console.error('Error fetching influencers:', error);
        // Handle the error
      }
}

export {RegisterUser,LoginInfluen,GetInfl_Info,updateInfluencer,VerifyEmail,
  logoutInfl,fetchInfluencers,forgotInfluencer,OTPInfluencer,PaswwordViaOTPInfluencer,
  searchInfluencers,fetchAllInfluencers,AllSearchInfluencersDebounce};