import React,{useState} from 'react'
import styles from "./SignIn.module.css"
import { useNavigate } from "react-router-dom";


const RadioValidationForm = () => {
    const [selectedOption, setSelectedOption] = useState(''); // State to track the selected radio option
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedOption) {
      // Form is valid, you can perform your action here
      //alert(`You selected: ${selectedOption}`);
     // setFormSubmitted(true);
      if(selectedOption==="Are you an influencer?"){
        navigate('/auth/signup');
      }else{
        navigate('/');
      }
    } else {
      alert('Please select an option before submitting.');
    }
  };
  return (
    <div className="container mt-4">
        <div className="row">
          <div className="col-lg-7">
            <div className={`p-4 ${styles.left_side} `}>
              <div>
                <h2 className={`text-center ${styles.head_name} d-block`}>Alpha Project</h2>
                <p className={`text-center d-block ${styles.p_name}`}>took right step with us...</p>
              </div>
            </div>
          </div>
    <div className={`${styles.card} mx-auto mt-5`}>
      <h2 className="p-3">Influencer Validation Form</h2>
      <form className={`${styles.form_width} p-3`} onSubmit={handleSubmit}>
        <div className={`${styles.input_icons} mb-4 mt-2`}>
          <label className={styles.radio_label}>
            <input
              type="radio"
              value="Are you an influencer?"
              checked={selectedOption === 'Are you an influencer?'}
              onChange={handleOptionChange}
            />
            <span className={styles.radio_text}>
            Are you an influencer?
            </span>
          </label>
        </div>
        <div className={`${styles.input_icons} mb-4 mt-2`}>
          <label className={styles.radio_label}>
            <input
              type="radio"
              value="Are you looking for influencer?"
              checked={selectedOption === 'Are you looking for influencer?'}
              onChange={handleOptionChange}
            />
            <span className={styles.radio_text}>
            Are you looking for influencer?
            </span>
          </label>
        </div>
        <div className={styles.radio_submit}>
          <button className='button btn btn-primary float-left w-100' type="submit">Submit</button>
        </div>
      </form>
    </div>
    </div>
    </div>
  )
}

export default RadioValidationForm