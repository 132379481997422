import CryptoJS from 'crypto-js';

// Use the hex key from the backend
const keyHex = `${process.env.REACT_APP_NEW_ENCRYPT_KEY}`
// console.log(keyHex,"here");
const keyWordArray = CryptoJS.enc.Hex.parse(keyHex);

const decrypt = (encryptedText) => {
  // Split the encrypted data into IV and ciphertext
  const textParts = encryptedText.split(':');
  const iv = CryptoJS.enc.Hex.parse(textParts.shift());
  const ciphertext = CryptoJS.enc.Hex.parse(textParts.join(':')); 
  // Decrypt the ciphertext using the key and IV
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, keyWordArray, { iv });  
  // Convert decrypted data to UTF-8 string
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export {decrypt}