import React, { useState,useContext,useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";
import { OTPInfluencer, VerifyEmail, forgotInfluencer} from "../../services/auth-service/InflAccountService";
import { UserContext } from '../../App';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { useLocation } from 'react-router-dom';
import { OTPClient, VerifyEmailClient, forgotClient } from '../../services/auth-service/clientAccountService';

const OTPVerify = () => {
    const [alertData, setAlertData] = React.useState(null);
    const location = useLocation();
    const data = location.state && location.state.data;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [timer, setTimer] = useState(120); // 2 minutes = 120 seconds
    const [isResendActive, setIsResendActive] = useState(false);
    const [inputdata,setinputdata] = useState({
       otp:'',
    })
    useEffect(() => {
      let interval = null;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTime) => prevTime - 1);
        }, 1000);
      } else {
        setIsResendActive(true);
      }
      return () => clearInterval(interval);
    }, [timer]);
  
    const handleinput =(e)=>{
      const name = e.target.name;
      const value = e.target.value;
      setinputdata({...inputdata,[name]:value})
    }

   const postData=async (e)=>{
      e.preventDefault();
        let res;
      const updatedInputdata = {
        ...inputdata,
        ...data,
      };
      if(data.toggler==="influencer"){
        res = await OTPInfluencer(updatedInputdata)
      }else if(data.toggler==='client'){
        res = await OTPClient(updatedInputdata)
      }
      if(data.subject !== 'Your One-Time Password (OTP) for Password Reset' && data.toggler==="influencer" && res.status===200){
            try {
              // Make a PUT request to the API endpoint
              const response = await VerifyEmail(data.email)
              if(response===204){
                setAlertData({
                    message: 'Unable to Verify Influencer!!'
                  });
              }else{
              setLoading(false);
              navigate('/influencerhome')}
            } catch (error) {
              console.error('Error updating verifyEmail:', error);
              setAlertData({
                message: 'Error updating verifyEmail',
              });
              setLoading(false);
            }
      }else if(data.subject !== 'Your One-Time Password (OTP) for Password Reset' && data.toggler==="client" && res.status===200){
        try {
          // Make a PUT request to the API endpoint
          const response = await VerifyEmailClient(data.email)
          if(response===204){
            setAlertData({
                message: 'Unable to Verify Influencer!!'
              });
          }else{
          setLoading(false);
          navigate('/influencerhome')}
        } catch (error) {
          console.error('Error updating verifyEmail:', error);
          setAlertData({
            message: 'Error updating verifyEmail',
          });
          setLoading(false);
        }
    }
      else{
       if(res === 400 || !res ){
          setAlertData({
            message: 'Invalid User!!',
          });
       }else{
        setinputdata([])
        navigate('/changeforgotpassword',{ state: { data: updatedInputdata } })
       }
      }
    }

    const handleResend =async (e) => {
      setIsResendActive(false);
      setTimer(120); // Restart the timer
      // Add your resend logic here
      e.preventDefault();
    let res;
    if (data.toggler === 'influencer') {
      res = await forgotInfluencer(data)
    } else if (data.toggler === 'client') {
      res = await forgotClient(data)
    }
    if (res === 400 || !res) {
      setAlertData({
        message: 'Invalid User!!',
      });
    }else{
      setAlertData({
        message: 'OTP sent successfully!',
      });
    }
    };
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

      return(
  <>
  <section className="inflanar-signin pd-top-120 pd-btm-120">
  <div className="container" style={{ maxWidth :"720px"}}>
  <div className="row">
  <div className="col-12">
  <div className="inflanar-signin__body">
  <div className="row">
  <div className="col-lg-12 col-12">
  <div className="inflanar-signin__logins">
  
  <div className="inflanar-signin__header mg-btm-10">
  <div className="inflanar-signin__heading">
  <h4 className="inflanar-signin__title">Enter 6 digit OTP</h4>
  </div>
  
  </div>
  
  <div className="tab-content mg-top-30" id="nav-tabContent">
  <div className="tab-pane fade active show" id="in-tab10" role="tabpanel">
  <div className="inflanar-signin__inner">
  <form  method='POST'>
  <div className="row">
  <div className="col-12">
  <div className="form-group inflanar-form-input mg-top-20">
  <label>OTP*</label>
  <input className="ecom-wc__form-input" type="number" name="otp" 
  placeholder="Enter OTP" required="required" value={inputdata.otp}
  onChange={handleinput} />
  </div>

  <div className="form-group mg-top-40">
  <button type="submit" className="inflanar-btn" onClick={postData}><span>Submit</span></button>
  </div>
  
  <div className="inflanar-signin__bottom">
                                  {isResendActive ? (
                                    <button className="inflanar-btn" onClick={handleResend}>
                                      Resend OTP
                                    </button>
                                  ) : (
                                    <p>Resend OTP in {formatTime(timer)}</p>
                                  )}
                  </div>
  </div>
  </div>
  </form> 
  </div>
  </div>
  
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  {alertData && (
    <div className="overlay">
      {/* You can add an overlay to dim the background if needed */}
      <AlertMessage
        message={alertData.message}
        type={alertData.type}
        onClose={() => setAlertData(null)}
      />
    </div>
  )}
  </>
      )
}

export default OTPVerify