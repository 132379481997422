import React,{useEffect,useContext} from 'react'
import { useNavigate } from "react-router-dom";
import { logoutInfl } from '../../services/auth-service/InflAccountService';
import { UserContext } from '../../App';


const Logout = () => {
    const navigate = useNavigate();
    const {state,dispatch} = useContext(UserContext);


    const callLogout = async ()=>{
        try {
          const response =await logoutInfl();
          if(!response.status === 200){
            const error = new Error(response.error) 
            throw error;
          }
          dispatch({type:'USER',payload:false})
          navigate('/');
        } catch (error) {
          console.log(error);
        }
      }
    useEffect(() => {
        callLogout();
    }, [])

  return (
    <>Logout</>
  )
}

export default Logout