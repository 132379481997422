import React, { useState,useContext } from 'react'
import { Link,useNavigate } from "react-router-dom";
import { OTPInfluencer, PaswwordViaOTPInfluencer, forgotInfluencer} from "../../services/auth-service/InflAccountService";
import { UserContext } from '../../App';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm,Controller } from 'react-hook-form';
import { PaswwordViaOTPClient } from '../../services/auth-service/clientAccountService';


const schema = yup.object().shape({
newpassword:yup.string().required('Password is required')
.min(8, 'Password must contain atleast eight characters with one uppercase letter, one number and one special character')
.matches(
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  'Password must contain atleast eight characters with  one uppercase letter, one number and one special character'
),
confirmpassword:yup.string().oneOf([yup.ref('newpassword'),null],'Password must match')}
)

const ChangeFgtPawd = () => {
    const location = useLocation();
    const [alertData, setAlertData] = React.useState(null);
    const navigate = useNavigate();
    const {register,handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })
      const updatedata = location.state && location.state.data;

      const storedata = async(datainp,e) => {
        const datainpWithEmail = {
            ...datainp,
            ...updatedata
          };
        try {
        e.preventDefault();
        let res;
        if(updatedata.toggler==="influencer"){
         res =await PaswwordViaOTPInfluencer(datainpWithEmail);
        }else if(updatedata.toggler==='client'){
         res =await PaswwordViaOTPClient(datainpWithEmail);
        }
        if(res === 400 || !res ){
          setAlertData({
            message: 'Invalid User!!',
          });
       }else{
        setAlertData({
            message: 'Updated Password!',
          });
        navigate('/signin')
       }
        // You can perform additional actions here, such as saving the updated values to a database.
       } catch (error) {
        console.log(error);
       }
      };
      return(
  <>
  <section className="inflanar-signin pd-top-120 pd-btm-120">
  <div className="container" style={{ maxWidth :"720px"}}>
  <div className="row">
  <div className="col-12">
  <div className="inflanar-signin__body">
  <div className="row">
  <div className="col-lg-12 col-12">
  <div className="inflanar-signin__logins">
  
  <div className="inflanar-signin__header mg-btm-10">
  <div className="inflanar-signin__heading">
  <h4 className="inflanar-signin__title">Enter New Password</h4>
  </div>
  </div>
  
  <div className="tab-content mg-top-30" id="nav-tabContent">
  <div className="tab-pane fade active show" id="in-tab10" role="tabpanel">
  <div className="inflanar-signin__inner">
  <form onSubmit={handleSubmit(storedata)}>
  <div className="row align-items-center">
  <div className="col-lg-12 col-12">

  <div className="inflanar-supports__password--form">
  
  <div className="form-group inflanar-form-input mg-top-20">
  <label>New Password*</label>
  <input className="inflanar-signin__form-input" 
  placeholder="●●●●●●●●●●●●"  type="password" name="newpassword" required="required" 
  // id={`password`}
  // onChange={(e) => handleChange('password', e.target.value)}
  {...register('newpassword')}
  />
  <p className='text-danger'>{errors.newpassword?.message }</p>
  </div>
  <div className="form-group inflanar-form-input mg-top-20">
  <label>Confirm Password*</label>
  <input className="inflanar-signin__form-input" 
  placeholder="●●●●●●●●●●●●"  type="password" name="confirmpassword" required="required" 
  {...register('confirmpassword')}
  />
  <p className='text-danger'>{errors.confirmpassword?.message }</p>
  
  </div>
  <div className="inflanar__item-button--group mg-top-50">
  <button className="inflanar-btn" type="submit"><span>Update Password</span></button>

  </div>
  
  </div>
  </div>
  </div></form>
  
  </div>
  </div>
  
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  {alertData && (
    <div className="overlay">
      {/* You can add an overlay to dim the background if needed */}
      <AlertMessage
        message={alertData.message}
        type={alertData.type}
        onClose={() => setAlertData(null)}
      />
    </div>
  )}
  </>
      )
}

export default ChangeFgtPawd