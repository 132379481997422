import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./Faq.module.css";
import { Link } from "react-router-dom";

const Faq = () => {
  const [faq] = useState([
    {
      Question: "What is an influencer?",
      Answer:
        "An influencer is a content creator with a significant online following, often on social media, who has the power to impact the opinions and behaviors of their audience.",
    },
    {
      Question: "How do influencers earn money?",
      Answer:
        "Influencers generate income through brand partnerships, sponsored content, affiliate marketing, and collaborations with companies seeking to leverage their online presence.",
    },
    {
      Question:
        "What types of influencers exist?",
      Answer:
        "Influencers come in various categories, including micro-influencers (smaller, niche-focused), macro-influencers (large following), niche influencers (specialized in a particular topic), and celebrity influencers (well-known personalities).",
    },
    {
      Question:
        "How do brands choose influencers?",
      Answer:
        "Brands select influencers based on factors like relevance to their target audience, engagement levels, reach, authenticity, and the influencer's track record in successful collaborations with other brands.",
    },
  ]);
  return (
    <div className="dm mt-4 mb-4  p-4">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <h4 className="text-info">
            Frequently asked Questions about <Link to="/" className="navbar-brand text-italic">Alpha</Link>
          </h4>
          <hr />
        </div>
      </div>
      {faq.map((value, index) => {
        return (
          <div
            className="row mt-2 accordion accordion-icons"
            id="accordion"
            key={"Faqno" + index}
          >
            <div className="col-lg-8 col-md-8 col-sm-12 col-12 card bg-transparent border-0 ">
              <div className="card-header border-primary mt-1 ">
                <div className="row cursor-pointer">
                  <div className="col-11">
                    <h5
                      data-toggle="collapse"
                      data-target={"#Faqno" + index}
                      aria-expanded="false"
                      aria-controls={index}
                      id={index}
                    >
                      {value.Question}
                    </h5>
                  </div>
                  <div className="col-1">
                    <FontAwesomeIcon
                      className={`float-right ${styles.plus} text-info`}
                      data-toggle="collapse"
                      data-target={"#Faqno" + index}
                      aria-expanded="false"
                      aria-controls={index}
                      id={index}
                      icon={faPlus}
                    />
                    <FontAwesomeIcon
                      className={`float-right ${styles.minus} text-info`}
                      data-toggle="collapse"
                      data-target={"#Faqno" + index}
                      aria-expanded="false"
                      aria-controls={index}
                      id={index}
                      icon={faMinus}
                    />
                  </div>
                </div>

                <div
                  id={"Faqno" + index}
                  className="collapse"
                  aria-labelledby={index}
                  data-parent="#accordion"
                >
                  <p className="lead  ml-2">
                    {"-"} {value.Answer}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Faq;
