// categories.js
export const categories = [
    "Lifestyle",
    "Health & Fitness",
    "Food & Nutrition",
    "Fashion",
    "Author/Literature",
    "Spiritual & Motivational",
    "Education",
    "Parenting Coach",
    "Career Coach",
    "Technology",
    "Live spaces – Interiors",
    "Travel & Tourism",
    "Sports",
    "Professional",
    "Adventure",
    "Astrology",
    "Photographer",
    "Others",
];
  
 export const followers = [
    "0-50k",
    "51k-100K",
    "100K-500K",
    "501K-1M",
    "1M-5M",
    "Above 5M",
  ];