import HttpService from "../http-service/HttpService";

const getimeslot = async (influencerId)=>{
    try {
        const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/influencertimeslot?influencerId=${influencerId}`);
        return response
      } catch (error) {
        console.error('Error fetching selected dates:', error);
      }
}

///api/bookedinfluencertimeslot
const bookedtimeslot = async (influencerId)=>{
  try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/bookedinfluencertimeslot?influencerId=${influencerId}`);
      return response
    } catch (error) {
      console.error('Error fetching selected dates:', error);
    }
}


const markTimeSlot = async(influencerId,dates,startTime,endTime,meetingType,participants)=>{
    try {
        const res = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/markFree`, {
          influencerId,
          dates,
          startTime,
          endTime,
          meetingType,
          participants
        });
        return res.status;
      } catch (error) {
        console.error('Error marking dates as free:', error);
      }
}

const updateSlot = async (data) => {
  try {
    const response = await HttpService.put(`${process.env.REACT_APP_API_URL}/api/updateslots`, data);
    return response
    // Handle success, e.g., update state or display a success message
  } catch (error) {
    console.error('Error updating time slot:', error.response.data.message);
    // Handle error, e.g., display an error message
  }
};

// /api/deleteslot
const deletedSlot = async (data) => {
  const _id = data._id
  try {
    const response = await HttpService.delete(`${process.env.REACT_APP_API_URL}/api/deleteslot/${_id}`);
    return response
    // Handle success, e.g., update state or display a success message
  } catch (error) {
    console.error('Error updating time slot:', error.response.data.message);
    // Handle error, e.g., display an error message
  }
};

export {getimeslot,markTimeSlot,updateSlot,deletedSlot,bookedtimeslot}