import React,{useRef,useEffect} from 'react'

const PrivacyAndPolicy = () => {
    const targetRef = useRef(null);

    useEffect(() => {
      // Scroll to the top of the target component when the component is mounted
      if (targetRef.current) {
        const topPosition = targetRef.current.offsetTop;
        window.scrollTo({
          top: topPosition,
          behavior: 'smooth', // Optionally, add smooth scrolling
        });
      }
    }, []);
  return (
    <section className="inflanar-bg-cover pd-top-120 pd-btm-120" ref={targetRef}>
    <div className="container">
    <div className="row">
    <div className="col-12 text-justify">
    
    <div className="inflanar-page-content mg-top-40">
    <h3>Privacy Policy</h3>
    <p>
    I. Objective and Scope
    <br/><br/>
    CASAASSURE LLP ("We", "Us", "Company", "IN KORERO") values the trust placed in Us by data subject ("You", "Your", "Users", "Influencer", "Client") and therefore, We follow the highest standards of privacy guidelines to protect the Information shared by You with Us.
    <br/>
    
    This Privacy Policy (&quot;Privacy Policy&quot;) explains how We collect, use, share, disclose and protect the Content,
Information and Personal Information, as well as the IN KORERO Content shared, with or collected by
Company from User and/or Influencers while using the IN KORERO platform (&quot;IN KORERO&quot;, &quot;Our website
and/or application&quot;).
    
    <br/>
    All capitalized terms that have not been specifically defined herein shall have the same meaning as provided
    under the Terms and Conditions.    
    <br/>
    
    BY USING THE IN KORERO PLATFORM OR BY OTHERWISE GIVING US YOUR INFORMATION,
    YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND
    POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY
    POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF
    YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO
    CHANGE, MODIFY, ADD OR DELETE THE TERMS OF THIS PRIVACY POLICY INCLUDING THE
    PORTIONS THEREOF, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH
    THIS PRIVACY POLICY AT ANY TIME, DO NOT USE THE IN KORERO PLATFORM OR PROVIDE US
    WITH ANY OF YOUR INFORMATION. IF YOU USE THE IN KORERO PLATFORM ON BEHALF OF
    SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU
    REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT
    THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON
    BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF
    SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.    
    <br/>
    
    This Privacy Policy should be read in conjunction and together with the Terms and Conditions.    
    <br/>
    <br/>
    
    II. User and/or Influencer Consent
    
    <br/>
    <br/>
    By using IN KORERO platform (for example, when You register for an account, book a Session, communicate
      with Us on Our IN KORERO platform and/or browse on the IN KORERO platform), You agree to provide
      consent to Our collection, use and sharing of Your Content including Personal Information as described in this
      policy.    <br/>
    
    
      By using IN KORERO platform You also consent to sharing of Your Content including Personal Information
      with third parties for marketing and advertising purposes. In case of any User and/or Influencer travelling
      internationally and accessing the IN KORERO platform, then We will consider INDIA as Your primary country
      and the consents obtained at the time of registration will be valid for Your processing of Personal Information
      and/or the Information.    <br/>
      Please keep in mind that when You provide information on a third-party site or platform (for example, via Our
        platform like social media login) We collect Your Information through those third-party sites which are covered
        by this Privacy Policy. Information the third-party site or platform collects is subject to the third-party site or
        platform&#39;s privacy practices.    <br/>
    
    
        Please also keep in mind that IN KORERO platform may contain links to other sites not owned or controlled by
        Us and We are not responsible for the privacy practices of those sites. We encourage You to make yourself
        aware when You leave the IN KORERO platform and to read the privacy policies of other sites that may collect
        Your Information including the Personal Information.
        <br/>
        Privacy choices You have made on the third-party sites or platform will not apply to Our use of the Content
including Personal Information We have collected directly through Our IN KORERO platform.    
    <br/>
    <br/>
    III. Collection and Use of Personal Information
    <br/>
    <br/>
    Personal Information or Personally Identifiable Information together known as &quot;Personal Information&quot; is
defined under the SPI Rules under the Information Technology Act, 2000 to mean any information that relates
to a natural person, which, either directly or indirectly, in combination with other information available or likely
to be available to a body corporate, is capable of identifying such person. When anonymous information is
directly or indirectly associated with Personal Information, the resulting information also is treated as Personal
Information. The Content collected from You by IN KORERO may constitute Personal Information or sensitive
personal data or Information under the SPI Rules.<br/>
IN KORERO will be free to use, collect and disclose Your Information that is freely available in the public
domain (example Your social media accounts) without Your consent.
    <br/>
    <br/>
    
    III. Content (Information and Personal Information) We collect
    <br/><br/>
    
    
    a. We may ask for and collect the following Content including the Information and the Personal Information
    about You when You use the IN KORERO platform. We would like to emphasis here that without this Content
    We may not be able to provide You with all the requested Sessions in this regard; Information collected includes
    but not limited to: first and last name, email id, phone no/mobile number, social media identities example Your
    Instagram/ X(Twitter)/ Facebook / You Tube account/LinkedIn, date of birth, gender, category of influence,
    categories of interests and purpose of the Session.    
    <br/>
    b. We collect Content (Information and Personal Information) about You and Your use of the In KORERO
platform, Your Session with the Influencer and/or any advertising. The information includes:
    <br/>
    i. Activities on the IN KORERO platform such as type of Content and the IN KORERO Content (as applicable),
    Session with the Influencer, history, search    <br/>
    ii. Your interactions on Our email, and the IN KORERO platform.
    <br/>
    c. We identify and Use Your Information including the IP address to help diagnose any problems with Our
    server, and to administer the error.
    <br/>
    d. We may track certain Content including Information and Personal Information about You based upon Your
behaviour on IN KORERO platform. We use this Content including Information for conducting internal
research on Our User’s and/or Influencer’s interests, and behaviour to better understand, protect and serve Our
Users and/or Influencers. This information will be compiled and analysed including on an aggregated basis.
    <br/>
    e. We may also collect Your Content including the Information to track User and/or Influencer behaviour and
preferences for internal analytics and research. We may also use Your information: (i) To evaluate Your interest
for various IN KORERO platform services, offers; To perform analytics and conduct customer research, to
determine Your interest, for identifying Content that generate sales and to analyse traffic patterns.    
    <br/>
    
    f. If You choose to post messages and update, modify the Content on IN KORERO platform’s social media
    accounts, message boards, chat rooms or other message areas or leave feedback, We will collect that
    Information provided by You to Us. We retain this Information and the Content as necessary to verify Your
    account, resolve disputes, provide customer support and troubleshoot problems as permitted by law.    
    <br/>
    
    g. If You send Us personal correspondence, such as emails or letters, or if other User and/or Influencers or third-
    parties send Us correspondence about Your activities or postings on IN KORERO platform, We may collect and
    store such Information and the Content.    
    <br/>
    
    h. We will occasionally ask You to complete optional online surveys which becomes part of the Information.
    These surveys may ask You for contact information and demographic information (like location, age, etc.). We
    use this data to tailor Your experience on IN KORERO platform, providing You with Content that We think You
    might be interested in and to display Content according to Your preferences.    <br/>
    
    
    i. We use Information to provide, analyse, administer, enhance and personalize Our service and marketing
    efforts, to process Your registration, Your orders, payments, and to communicate with You related to below
    mentioned points. For example, We use Information to let You know the details of the Influencer before
    booking, let the Influencer know about the Session purpose, provide You with customized and personalized
    
    recommendations for Influencer Session and help Us quickly and efficiently respond to inquiries and prevent,
    detect and investigate potentially prohibited or illegal activity.
    <br/>
    j. communicate with You concerning Our service (for example by email, text messaging, online messaging
      channels, and phone calls), so that We can facilitate in Session booking, Session conduction, send You news
      about Company details about new features available on IN KORERO platform, and special offers, promotional
      announcements, and consumer surveys, and to assist You with operational requests such as password reset
      requests.
      <br/>
      k. Notify You about changes in Terms and Conditions, allow You to participate in interactive features offered on
the IN KORERO platform to carry out Our successful Session conduction and its process for ensuring the
smoothness of the same. Information is sought to enforce and bind Our rights arising from any contracts entered
in to between You and Us, including for billing, chargeback and collection.
<br/>
l. We may also use Your Content including the Personal Information to contact You about Our own and third-
party services that may be of interest to You.
<br/>
m. We may reach out to the Influencer via email and/or mobile to get his/her bank account details for remitting
the Influencer fee.
<br/>    

    <br/>
    
    IV. Information We collect through third parties
    <br/><br/>
    If You link, connect or sign-in IN KORERO platform with a third-party service i.e. Google, Facebook,
X(Twitter), etc. they may collect that information that they send Us such as Your registration and profile
information. Below mentioned are the Information and/or the Content that may get collected through social
platforms such as: Facebook - Public information, Name , Email id, friend lists; Google - Email id;
Instagram/X(Twitter) - Email id, handle details , read tweets from Your timeline and see who the User and/or
Influencer is following.
    <br/>
    <br/>
    V. Billing Information<br/>
    
    <br/>
    IN KORERO platform uses third party payment gateway partners to process and facilitate the payment of Your
    fee for providing You time slot for the interaction with Influencers and/or Professionals. Based on Your payment
    of fees Our payment gateway partners collect information with respect to Your UPI account, credit/debit cards
    or other bank account details along with Your billing address. This information is stored by Our payment
    gateway partners. Such payment gateway partners are not controlled by Us. When You visit such payment
    gateways You do so at Your own choice and risk. These payment gateways may have their own privacy policies
    in place, which We recommend You review thoroughly. We do not assume any responsibility or liability for
    such payment gateways, the content of such payment gateways and their privacy practices, nor do We endorse
    them. We encourage You to familiarize yourself with the privacy statements provided by such payment
    gateways prior to providing them with information about You or entering into any transactions with them.
    <br/>
    IN KORERO shall not be liable, accountable or responsible for the Content including the Personal Information,
Information and sensitive personal data which You provide to these payment gateways.    
    <br/><br/>
    
    VI. Session Conduction    
    <br/>
    <br/>
    IN KORERO platform uses third party partners to process and facilitate the Session conduction and organising
    the Session including the Meeting invite for providing You the time slot for the interaction with Influencers. In
    this regard, the Information shared by the User and the Influencer may be stored at their websites and
    applications. Such partners are not controlled by Us. When You visit such websites and applications. You do so
    at Your own choice and risk. These third parties may have their own privacy policies in place, which We
    recommend You review thoroughly. We do not assume any responsibility or liability for such third-party
    partners, the content of such third-party partners and their privacy practices, nor do We endorse them. We
    encourage You to familiarize yourself with the privacy statements provided by such third-party partners, prior to
    providing them with information about You or entering into any transactions with them.    <br/>
    
    
    IN KORERO shall not be liable, accountable or responsible for the Content including the Personal Information,
    Information and sensitive personal data which You provide to these third-party partners.    <br/>
    
    
<br/>    
VII. Disclosure to third parties
    <br/>
    
    General Disclosure    <br/>
    
    
    a. At times the Company may make certain Content available to strategic partners that work with the Company
    to provide You with service, or that help the Company market to customers. For example, when You book a
    Session, You authorize the Company to exchange the Content You provide during the Session booking process.
    <br/>b. We use third-party advertising companies to serve personalised advertisements when You visit or use IN
    KORERO platform. These companies may use Your Content (including but not limited to Your name, address,
    email address or mobile number or other Personal Information) about Your visits or use to particular website,
    mobile application or services, in order to provide personalised advertisements about goods and services of
    interest to You.
    <br/>c. The IN KORERO platform may include links to other websites or applications. Such websites or applications
    are governed by their respective privacy policies, and are beyond Our reasonable control. Once You leave Our
    server (You can recognize Your location by checking the URL in location bar on Your browser), any kind of
    Information provided by You is governed by the privacy policy of the operator of the applications/website(s)
    You are visiting. Their policy may differ from Ours. If You can&#39;t find the privacy policy of any of these
    applications/websites via a link from the application&#39;s homepage, You should either leave the
    application/websites or contact the application owner(s) directly for more information.
    <br/>d. Presentation of Information to Our advertisers for helping them to understand Our audience(s) and to know
    the value of advertising on IN KORERO platform, is usually in form of aggregated statistics on traffic to various
    pages/Content within Our website and/or applications.    
    <br/>
    
    Service Providers    <br/>
    e. IN KORERO platform may provide links to various third-party websites that may collect Your Personal
Information. Use of Your Information collected by such third-party platform will be governed by the privacy
policy of such third-party platform. Company has no control on the operation of such third-party websites and
You may share Information with such third party at Your sole risk. Company shall not be liable to You for any
misappropriation of Your Personal Information by such third party.
<br/>f. We may share Your Content including the non-personally identifiable Information publicly and with Our
partners - like publishers, advertisers, developers, or rights holders. For example, We share Your Information
publicly to show trends about the general use of Our IN KORERO platform. We also allow specific partners to
collect Information from Your browser or device for marketing/promotions and measurement purposes using
their own cookies or similar technologies.
    <br/>
    Promotional Offers    <br/>
    
    g. We share or disclose Your Content including Personal Information outside of the Company for purposes of facilitating any promotional offers. For example, providing e-mail related services such as marketing newsletters, promotions and offers. You can opt out from receiving promotional offers 
    and other marketing material. If You wish to continue or to opt out from receiving such messages, You can do so by indicating Your preference at any time through Our website and/or application.
    <br/>
    <br></br>

    
    VIII. Business Transfers    <br/><br/>
    We provide Personal Information to Our affiliates and other trusted businesses or persons to process it for Us, based on Our instructions and in compliance with Our Privacy Policy and any other appropriate confidentiality and security measures. For example, We use service providers to help Us with customer support.    
    <br/><br/>
    
    IX. Legal    <br/><br/>
    In the event of any requirement by court order, government, or quasi-government agency to 
    disclose Your Content (including Information and Personal Information), We will disclose the Content as may be legally required. We may also disclose Your Content, if We, in good faith believe 
    that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.
    <br/>
    In the event Company is merged with or acquired by another company, You acknowledge that We and Our affiliates may share Your Personal Information and the Content, wholly or in part, with another business entity.    
    <br/><br/>
    
    X. Managing Your Information and Content
    <br/><br/>
    a. You can access and update some of Your Personal Information and Information through Your profile. If You
    have chosen to connect Your IN KORERO platform account to a third-party application, like Facebook,
    Instagram, Google or X(Twitter), You can change Your settings and remove permission for the application by
    changing Your account settings. You are responsible for keeping Your Personal Information and Information up-
    to-date.
   <br/> b. Rectification of inaccurate or incomplete Personal Information and Content: You can ask Us to correct
inaccurate or incomplete Personal Information, Information and Content concerning You (this is the correction
and/or modification in the Content which You cannot update yourself within Your IN KORERO platform
account) by sending Us an e-mail.
<br/>c. Data Retention and Erasure: We retain Your Personal Information, Information and the Content for as long as
necessary. This is required for the performance of the contract between You and Us and to comply with Our
legal obligations. If You no longer want Us to use Your Information, Information and the Content, then You can
request that We erase Your Personal Information, Information and the Content and close Your IN KORERO
platform account. Please note that upon Your request for the erasure of Your Personal Information, We may still
need to do the following:
<br/>i. retain some of Your Content including Personal Information and Information as necessary for Our legitimate
business interests, such as fraud detection and prevention and enhancing safety. For example, if We suspend an
account for fraud or safety reasons, We may retain certain Content from that account to prevent that User and/or
Influencer from opening a new account in the future.
<br/>ii. retain and use Your Content including Personal Information to the extent necessary to comply with Our legal
obligations. For example, We may keep some of Your Content for tax, legal reporting and auditing obligations.
i<br/>iii. maintain copies of Content including Your Information and Personal Information, to protect from accidental
or malicious loss and destruction, residual copies of Your Content may not be removed from Our backup
systems for a limited period of time.
    <br/><br/>
    
    
    XI. Children&#39;s Privacy    <br/><br/>
    The IN KORERO platform is intentionally not designed for or directed at persons less than 18 years of age.
    Company does not knowingly permit any person who is under 18 years of age to register on IN KORERO
    platform or to provide any other personally identifying Content including Information. If Company becomes
    aware that any PII of persons less than 18 years of age has been collected by the Company without verified
    parental consent, then Company will take the appropriate steps to delete any such Content.    <br/>
    
    
    
    <br/>
    XII. Security and compliance with laws
    <br/><br/>
    We are continuously implementing and updating administrative, technical, and physical security measures to
help protect Your Content against unauthorized access, loss, destruction, or alteration. Some of the safeguards
We use to protect Your Content are firewalls and data encryption, and Information access controls. If You know
or have reason to believe that Your IN KORERO platform account credentials have been lost, stolen, altered, or
otherwise compromised or in case of any actual or suspected unauthorized use of Your account, please contact
Us by contacting Our customer support team.
<br/><br/>XIII. Change in Policy
<br/><br/>
This Privacy Policy is subject to change from time to time. We reserve the right, at Our sole discretion, to
modify the terms of this Privacy Policy from time to time in order to ensure compliance with applicable laws
(&quot;Updated Terms&quot;). The Updated Terms shall be effective immediately and shall supersede these terms of this
Privacy Policy. We will not be under an obligation to notify You of any changes to this Privacy Policy except if
the changes made to the policy significantly affects Your rights or as may be required by law. You shall be solely
responsible for reviewing the Privacy Policy from time to time for any modifications. By continuing to use IN

KORERO platform after the updated Terms have been published, You give Your consent to the said change in
policy and affirm Your agreement to the updated Terms.
<br/>
Security and Account Access: The security of Your Content is important to Us. We use a variety of physical,
technical, and administrative measures to safeguard Information in Our possession against loss, theft and
unauthorized use, disclosure, and modification. Please note that there is always a possibility that a breach in data
transmission or storage may occur and We cannot guarantee the security of any Information from or about You
100% of the time. Notwithstanding the above, Company is not responsible for the confidentiality, security or
distribution of your Content including Personal Information by Our partners and third parties outside the scope
of Our agreement with such partners and third parties. Further, We shall not be responsible for any breach of
security or for any actions of any third parties or events that are beyond the reasonable control of IN KORERO
including but not limited to, acts of government, computer hacking, unauthorised access to computer data and
storage device, computer crashes, breach of security and encryption, poor quality of Internet service or mobile
service of the User and/or Influencer etc.
<br/>
Please note that if You allow others to use Your account (including others You permit to set up profiles under
  Your account), they may be able to view Information about Your account or any Content under Your account.
  <br/>
  If You require any clarification regarding the use of Your Content or this Privacy Policy or grievances with
respect to use of Your Content including Personal Information, please email Us at <a className='text-primary' href='https://inkorero.com/contactus'>support@inkorero.com</a>.
<br/><br/>
Cookie Policy
<br/><br/>
Cookies Information: When you visit our Platform, we may send one or more cookies - a small text file
containing a string of alphanumeric characters - to your computer that uniquely identifies your browser and lets
us help you log in faster and enhance your navigation through the Platform. A cookie may also convey
anonymous information about how you browse the service to us. A cookie does not collect Personal Information
about you. We use both session cookies and persistent cookies. A persistent cookie remains on your hard drive
after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the site.
Persistent cookies can be removed by following your web browser’s directions. A session cookie is temporary
and disappears after you close your browser. You can reset your web browser to refuse all cookies or to indicate
when a cookie is being sent. However, some features of the service may not function properly if the ability to
accept cookies is disabled.
    </p>
    <br/>
    </div>
    
    
    
    </div>
    </div>
    </div>
    </section>
  )
}

export default PrivacyAndPolicy