import React,{useState} from 'react'
import Editprofile from './EditProfile';
import styles from './clientedit.module.css'
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import { useNavigate } from "react-router-dom";
import { forgotClient } from '../../../services/auth-service/clientAccountService';
import BookInfluencer from '../bookInfluencer/BookInfluencer';
import {subjectEmail,emailMessage} from '../../../utils/common'


const Profile = ({initialValues}) => {
    const navigate = useNavigate();
    const [showOverlay, setShowOverlay] = useState(false);
    const [alertData, setAlertData] = React.useState(null);

    const handleToggleOverlay = () => {
        setShowOverlay(!showOverlay);
      };
    

    const [values] = useState(initialValues);
    const [inputdata] = useState({
        email:values.email,
        subject:subjectEmail,
        message: emailMessage
     })
    const data = {
        ...inputdata,
        toggler:"client"
    }
    const sendVerifyEmail =async (datareceived)=>{
        const res = await forgotClient(datareceived)
        if(res.status === 400 || !res ){
         setAlertData({
           message: 'Invalid User!!',
         });
      }else if(res.status===200){
        navigate('/otpverify',{ state: { data: data } })
      }
     }
  return (
    <>
    <div className={`${styles.paralleldivs} inflanar-profile-info mg-top-30`}>
  <div className='row'>
    <div className="col-lg-9 col-12">  
<div className="inflanar-profile-info__head">
<div className='d-flex'>
<h3 className="inflanar-profile-info__heading">Personal Information</h3>

</div>
</div>
<ul className={`inflanar-profile-info__list inflanar-dflex-column list-none ${styles.profilelength}`}>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Name :</h4>
<p className="inflanar-profile-info__text">{values.firstName} {values.lastName}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Email :</h4>
<p className="inflanar-profile-info__text">{values.email}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Phone :</h4>
<p className="inflanar-profile-info__text">{values.phone}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Category :</h4>
<p className="inflanar-profile-info__text">{values.category}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Gender :</h4>
<p className="inflanar-profile-info__text">{values.gender}</p>
</li>
</ul>
</div>
<div className='ml-auto col-lg-3 col-12 mt-3 mt-md-0'>
<button className={`btn btn-outline-info mt-2 ${styles.buttonsize}`}
 onClick={handleToggleOverlay}>Edit Profile</button>
 <br />
 <button className={`btn btn-outline-info mt-2 ${styles.buttonsize}`}
 onClick={()=>sendVerifyEmail(inputdata)} disabled={values.verifyEmail}>Verify Email</button>
 <br />
</div>
</div>
</div>
{showOverlay && (
    <Editprofile initialValues={values} onClose={handleToggleOverlay}/>
  )}
  {alertData && (
    <div className="overlay">
      {/* You can add an overlay to dim the background if needed */}
      <AlertMessage
        message={alertData.message}
        type={alertData.type}
        onClose={() => setAlertData(null)}
      />
    </div>
  )}
    </>
  )
}

export default Profile